import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./css/index.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { HelmetProvider } from 'react-helmet-async';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    }, 
    supportedLngs: ["en", "de"],
    fallbackLng: "en",
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },

  
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    
    <BrowserRouter>
      <HelmetProvider>
        {/* <ChakraProvider theme={theme}> */}
        <App />
        {/* </ChakraProvider> */}
      </HelmetProvider>
      
    </BrowserRouter>
  </React.StrictMode>
);
