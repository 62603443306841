import React from 'react';
import { Box, Heading, Text, List, ListItem, ListIcon, Link } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { MdCheckCircle } from 'react-icons/md';
import Navbar from '../components/Navbar';
import BottomBar from '../components/BottomBar';
import { useContext } from 'react';
import AuthContext from "../AuthContext";


const TermsOfService = () => {

    const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
    const { isSignedIn, role } = useContext(AuthContext);
    return (
        <Box>
            <Navbar loggedIn={isSignedIn && allowedRoles.includes(role)} />

            <Box px={10} py={5} mt={20} maxW={"100vw"}>
                <Heading as="h1" size="xl" mb={4}>
                    Nutzungsbedingungen für NutzerInnen der classrate Plattform
                </Heading>
                <Text mb={4}>
                    NutzerInnen können über diese Plattform Kurse bewerten und Bewertungen einsehen sowie sich in
                    Diskussion mit anderen NutzerInnen austauschen und in Zukunft weitere Dienstleistungen im Bereich
                    Ausbildung und Karriere erhalten.
                </Text>
                <Heading as="h2" size="md" mt={4}>
                    1. Anwendungsbereich
                </Heading>
                <Text mb={4}>
                    Diese Nutzungsbedingungen regeln das Vertragsverhältnis zwischen classrate und dem/der NutzerIn.
                </Text>
                <Heading as="h2" size="md" mt={4}>
                    2. Registrierung
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        2.1
                        Um die Dienste von classrate nutzen zu können, ist eine Registrierung notwendig. Die
                        Registrierung erfolgt durch Eingabe der Email Adresse des Nutzers/ der Nutzerin die ihm/ihr
                        durch die Hochschule in der er/sie immatrikuliert ist bereitgestellt wurde, und
                        Registrierungsbestätigung innerhalb der Bestätigungsmail über das angegebene Email Konto. Mit
                        der Registrierung erstellt der/ die NutzerIn ein Benutzerkonto („Konto“). Nur volljährige und
                        voll geschäftsfähige Personen dürfen sich registrieren. Die bei der Registrierung angegebenen
                        Daten müssen der Wahrheit entsprechen. Die Verwendung eines Pseudonyms ist zulässig.
                    </ListItem>
                    <ListItem>
                        2.2
                        classrate bietet Dienstleistungen im Bereich Ausbildung und Karriere für Studierende.
                    </ListItem>
                    <ListItem>
                        2.3
                        NutzerInnen dürfen kein neues Konto erstellen, wenn classrate ihr Konto sperrt.
                    </ListItem>
                    <ListItem>
                        2.4
                        classrate behält sich das Recht vor, Nutzernamen und Profilbilder zu entfernen, wenn classrate dies zum Schutz der Rechte Dritter für notwendig hält (zum Beispiel, wenn der/die InhaberIn einer Marke eine Beschwerde über einen Nutzernamen einreicht, welcher nicht dem echten Namen eines/einer NutzerIn entspricht).
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    3. Leistungen von classrate
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        3.1
                        Über die Plattform können NutzerInnen Kurse ihres Studiengangs bewerten und andere Bewertungen einsehen.
                    </ListItem>
                    <ListItem>
                        3.2
                        Durch aktive Teilnahme auf unserer Plattform können NutzerInnen Credits (“Karma”) erlangen, die sie wiederum in Zukunft für Prämien einlösen können.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    4. Nutzung der Plattform
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        4.1
                        Für die Bewertungen der Kurse sind die jeweiligen NutzerInnen, die die Inhalte verbreitet, selbst verantwortlich. classrate stellt lediglich die technische Plattform für seine NutzerInnen zur Verfügung, damit diese sich untereinander austauschen können. classrate nimmt keine Einflüsse auf die Inhalte seiner NutzerInnen, soweit dies nicht rechtlich geboten ist. Eine Verpflichtung, Bewertungen und andere Beiträge vor der Veröffentlichung auf etwaige Rechtsverletzungen zu überprüfen trifft classrate indes nicht.
                    </ListItem>
                    <ListItem>
                        4.2
                        NutzerInnen dürfen die auf classrate geteilten Inhalte ausschließlich für private Studien- und Lernzwecke nutzen.
                    </ListItem>
                    <ListItem>
                        4.3
                        Die Nutzung der Plattform ist kostenlos.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    5. Pflichten der NutzerInnen
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        5.1
                        Die NutzerInnen gewährleisten, dass sämtliche von ihnen hochgeladenen, geposteten und gesendeten Inhalte den rechtlichen Vorgaben des Strafgesetzbuches, des Jugendschutzes, des Datenschutzes sowie der gewerblichen Schutzrechte entsprechen und das Persönlichkeitsrecht Dritter achten. NutzerInnen dürfen keine Inhalte posten und keine Links auf Websites setzen, die die Rechte einer anderen Person oder das Gesetz verletzen.
                    </ListItem>
                    <ListItem>
                        5.2
                        Inhalte die diese Anforderungen nicht erfüllen, können andere NutzerInnen über den „Melden“-Button oder per Email an classrate melden. Werden Inhalte über den „Melden“-Button oder per Email gemeldet, so wird classrate eine Prüfung der gemeldeten Inhalte vornehmen und diese ggf. entfernen. classrate wird bezüglich der Prüfung von Inhalten nicht initial tätig, wird Solche aber gleichwohl entfernen, wenn classrate davon Kenntnis erlangt. Ein Anspruch auf Prüfung der Inhalte durch classrate kann hieraus nicht abgeleitet werden. Für das Erhalten von Direktnachrichten können NutzerInnen Präferenzen setzen und Chats von anderen NutzerInnen blockieren.
                    </ListItem>
                    <ListItem>
                        5.3
                        Die NutzerInnen stellen classrate von allen tatsächlichen und behaupteten Ansprüchen Dritter, einschließlich der Kosten der Rechtsverfolgung/-verteidigung, frei, die auf Handlungen oder Unterlassungen der NutzerInnen beruhen, insbesondere von Ansprüchen, die auf einer schuldhaften, rechtswidrigen oder missbräuchlichen Verwendung der Plattformen oder der Nichteinhaltung anwendbarer Vorschriften oder einem Verstoß gegen Rechte Dritter durch die NutzerInnen beruhen oder mit ihrer Billigung erfolgen. Die NutzerInnen haben classrate unverzüglich zu informieren, sollte ein solcher Verstoß drohen.
                    </ListItem>
                    <ListItem>
                        5.4
                        Die NutzerInnen dürfen die Plattform nur mit folgenden Einschränkungen nutzen:
                        <List  >
                            <ListItem>
                                <ListIcon as={SmallCloseIcon} />
                                Sie dürfen keinerlei Werbung posten.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={SmallCloseIcon} />
                                Sie dürfen nicht mittels automatisierter Mechanismen (wie Bots, Roboter, Spider oder Scraper) auf Inhalte oder Informationen von anderen NutzerInnen zugreifen.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen keine Viren oder anderen bösartigen Code hochladen oder versenden.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen keine Anmeldeinformationen einholen oder auf ein Konto zugreifen, das einer anderen Person gehört.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen andere NutzerInnen weder beleidigen noch einschüchtern oder belästigen.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen keine Inhalte posten oder versenden, die Hassreden enthalten, bedrohlich oder pornografisch sind, zu Gewalt auffordern oder Darstellungen von Nacktheit oder Gewalt enthalten.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen die Plattformen nicht für rechtswidrige, irreführende, bösartige oder diskriminierende Handlungen verwenden.
                            </ListItem>
                            <ListItem> <ListIcon as={SmallCloseIcon} />
                                Sie dürfen nichts tun, was das einwandfreie Funktionieren bzw. Erscheinungsbild der Plattformen blockieren, überbelasten oder beeinträchtigen könnten, wie etwa Denial-of-Service-Attacken, oder Funktionen der Plattformen stören könnten.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        5.5
                        Insbesondere sind Freitextfelder nicht mit personenbezogenen Daten zu befüllen, da dies für Direktnachrichten nie erforderlich sein wird und auch nicht von classrate intendiert ist.
                        Sind die oben genannten Anforderungen nicht erfüllt und erhält classrate hiervon Kenntnis, etwa durch die Betätigung der Meldefunktion durch andere NutzerInnen oder durch Erhalt einer entsprechenden Email, ist classrate berechtigt, die Inhalte ohne vorherige Benachrichtigung an die betroffenen NutzerInnen zu entfernen. Des Weiteren ist classrate berechtigt, das Konto der NutzerInnen ohne vorherige Benachrichtigung zu sperren oder zu löschen.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    6. Meldesystem und Moderation von Inhalten
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        6.1
                        classrate hat ein elektronisches Meldeverfahren eingeführt, um mutmaßlich rechtswidrige Inhalte zu überprüfen, die von Dritten auf der von classrate betriebenen Plattform veröffentlicht werden.
                    </ListItem>
                    <ListItem>
                        6.2
                        Wenn nach Überprüfung einer Meldung festgestellt wird, dass die gemeldeten Inhalte nicht mit den geltenden gesetzlichen Vorgaben vereinbar sind und/oder gegen die Regelungen dieser allgemeinen Geschäftsbedingungen verstoßen, behält sich classrate das Recht vor, die gemeldeten Inhalte zu sperren, zu entfernen oder anderweitig den Zugang dazu zu beschränken („Moderationsmaßnahme“). Das gleiche gilt für Inhalte, die classrate aufgrund einer freiwilligen Untersuchung und Überprüfung als rechtswidrig und/oder im Widerspruch zu den allgemeinen Geschäftsbedingungen einstuft. classrate ist jedoch nicht verpflichtet, die bereitgestellten Inhalte allgemein zu überprüfen.
                    </ListItem>
                    <ListItem>
                        6.3
                        classrate behält sich das Recht vor, die Bearbeitung einer Meldung auszusetzen, wenn nach sorgfältiger Prüfung aller Umstände offensichtlich ist, dass ein missbräuchliches Verhalten der meldenden Person vorliegt. Ein solcher Missbrauch liegt insbesondere vor, wenn die meldende Person die Meldefunktion wiederholt für eine Vielzahl von Fällen nutzt und die Überprüfung durch classrate ergibt, dass kein berechtigter Grund für die Meldung vorliegt. classrate wird die meldende Person, soweit technisch möglich, über die Aussetzung der Bearbeitung informieren.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    7. Urheberrechte
                </Heading>

                <List spacing={3} mb={4}>
                    <ListItem>
                        7.1
                        classrate kann einzelne oder sämtliche Inhalte, die die NutzerInnen auf der Plattform hochgeladen haben, und einzelne oder alle Links, die sie gesetzt haben, ohne Angabe von Gründen entfernen. Daraus kann nicht abgeleitet werden, dass classrate die hochgeladenen Inhalte oder gesetzten Links überprüft.
                    </ListItem>
                    <ListItem>
                        7.2
                        Wenn NutzerInnen die Rechte am geistigen Eigentum anderer verletzen, hat classrate das Recht, ihr Konto zu sperren.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    8. Kündigung
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        8.1
                        Die Vertragsbeziehung kann von jeder der Parteien jederzeit mit sofortiger Wirkung gekündigt werden. NutzerInnen können die Vertragsbeziehung ohne vorherige Benachrichtigung kündigen.
                    </ListItem>
                    <ListItem>
                        8.2
                        Die Kündigung kann unter der Schaltfläche Konto löschen erfolgen. Diese ist unter Einstellungen zu finden.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    9. Änderung dieser Geschäftsbedingungen
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        classrate behält sich das Recht vor, angemessene Änderungen an diesen Nutzungsbedingungen vorzunehmen, soweit diese für die NutzerInnen zumutbar sind. classrate wird seine NutzerInnen über Änderungen per E-Mail oder durch Veröffentlichung auf der Webseite benachrichtigen. Widersprechen NutzerInnen der Geltung der neuen Nutzungsbedingungen nicht innerhalb von einem Monat nach der Benachrichtigung und nehmen die Leistungen von classrate weiterhin in Anspruch, gelten die Änderungen als angenommen. Auf diese Folge wird classrate die NutzerInnen in der Benachrichtigung besonders hinweisen. Für die Rechtzeitigkeit des Widerspruchs ist dessen Absendetermin maßgeblich.
                    </ListItem>
                </List>
                <Heading as="h2" size="md" mt={4}>
                    10. Anwendbares Recht
                </Heading>
                <List spacing={3} mb={4}>
                    <ListItem>
                        Es gilt ausschließlich das Recht der Bundesrepublik Deutschland, soweit dadurch keine zwingenden gesetzlichen Bestimmungen des Staates, in dem der/ die NutzerIn seinen/ihren Wohnsitz oder gewöhnlichen Aufenthalt hat, eingeschränkt werden.
                    </ListItem>
                </List>



                {/* Weitere Abschnitte entsprechend hinzufügen */}
            </Box>
            <BottomBar />
        </Box>
    );
};

export default TermsOfService;
