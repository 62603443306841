import React from "react";
import {
  HStack,
  Select,
  Icon, IconButton, Spacer, useColorModeValue
} from "@chakra-ui/react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

// SEPERATE COMPONENT ======================================
export const SearchSort = ({ sortBy, sortOrder, onSortChange, onSortOrderChange }) => {
  const sortIcon = sortOrder === "asc" ? (
    <Icon as={FaSortUp} boxSize={4} />
  ) : (
    <Icon as={FaSortDown} boxSize={4} />
  );
  const { t } = useTranslation();

  const handleSortChange = (event) => {
    const newSortBy = event.target.value;
    onSortChange(newSortBy);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    onSortOrderChange(newSortOrder);
  };

  return (
    <HStack spacing={1} align="flex-end" m={1}>
      <Spacer />
      <Select  value={sortBy} variant='filled' bg={useColorModeValue( "main.elementBgLight", "main.elementBgDark")} onChange={handleSortChange}>
        <option value="name">{t("name")}</option>
        <option value="averageRating">{t("average_rating")}</option>
        <option value="averageGrade">{t("average_grade")}</option>
        <option value="wouldRecommend">{t("would_recommend")}</option>
        <option value="credits">{t("credits")}</option>
      </Select>
      <IconButton
        onClick={handleSortOrderChange}
        bg={useColorModeValue( "main.elementBgLight", "main.elementBgDark")}
        icon={sortIcon}
        variant={"outline"}
      ></IconButton>
    </HStack>
  );
};
