import React from 'react';
import { Box, Tag } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function CourseDetailTag({ text, link }) {
  const navigate = useNavigate();
  if (text != '[object Object]') {
    text = text.toString();
    text = text.charAt(0).toUpperCase() + text.slice(1);
  }


  return (
    <Box display="flex" justifyContent="center">
      <Tag
        key={text}
        size="sm"
        mt={1}
        mr={2}
        colorScheme="gray"
        borderRadius="full"
     
        alignContent={'center'}
        textAlign={'center'}
      >
        {text}
      </Tag>
    </Box>
  );
}
