import { useToast } from "@chakra-ui/react";
import { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export function AuthProvider({ children }) {

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const role = localStorage.getItem('role');
    const currentTime = Date.now();

    if (token && tokenExpiration && currentTime < tokenExpiration) {
      setIsSignedIn(true);
      setRole(role);
    } else {
      // handleSignOut();
    }
  }, []);
  const toast = useToast();
  const { t } = useTranslation();

  const handleSignIn = (token, expiresInSeconds, role) => {

    const tokenExpiration = Date.now() + expiresInSeconds * 1000;
    localStorage.setItem('authToken', token);
    localStorage.setItem('tokenExpiration', tokenExpiration);
    localStorage.setItem('role', role);
    setIsSignedIn(true);
    setRole(role);
  };

  const handleSignOut = (inactivity) => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('role');
    
    setIsSignedIn(false);
    if(inactivity){
    toast({
      title: t("session_expired"),
      description: t("session_expired_description"),
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }
  
  };

  const contextValue = {
    isSignedIn,
    handleSignIn,
    handleSignOut,
    role
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;
