import React from "react";
import { useMediaQuery } from "@chakra-ui/react";
import AuthLayout from "../components/layouts/AuthLayout";
import MobileAuthLayout from "../components/layouts/MobileAuthLayout";
import SignInComponent from "../components/SignInComponent";

function SignIn() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  return (isLargerThan768 ? (
    <AuthLayout>
      <SignInComponent/>
    </AuthLayout>
  ) : (
    <MobileAuthLayout>
      <SignInComponent/>
    </MobileAuthLayout>
  )

  );
}

export default SignIn;