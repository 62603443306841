import {Box, Heading, Link, Text} from '@chakra-ui/react';
import {Link as RouterLink} from "react-router-dom";

function UnauthorizedPage() {
    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bg="gray.100"
        >
            <Heading as="h1" size="2xl" mb={4}>
                Unauthorized
            </Heading>
            <Text fontSize="xl" mb={4}>
                Oops! You are not authorized to access this page.
            </Text>
            <Link
                as={RouterLink}
                to="/"
                color="main.primary"
                fontSize="xl"
                _hover={{textDecoration: 'underline'}}
            >
                Go to Home Page
            </Link>
        </Box>
    );
}

export default UnauthorizedPage