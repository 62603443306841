async function request(url, method, data = {}) {
  const token = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (method === undefined || method === null) {
    method = "GET";
  }

  const options = {
    method,
    headers,
    body: method === "GET" ? null : JSON.stringify(data),
  };

  try {


    const response = await fetch(url, options);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(
        responseData.error
          ? responseData.error
          : responseData.message || "Request failed"
      );
    }
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
}

export default request;
