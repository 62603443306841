import { Box, Button, Flex, HStack, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import request from "../api";
import { Tooltip } from "@chakra-ui/react";

export default function UserMessage({ message, onReplyClick, onDeleteClick }) {


    return (
        <Box
            key={message.id}
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
            borderRadius="md"
            boxShadow="md"
            p={4}
            mb={4}
        >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
                {message.username ? (
                    <HStack>
                        <Text fontWeight="bold" fontSize="lg">
                            {message.username}
                        </Text>
                        <Text fontSize="lg">
                            ({message.email})
                        </Text>
                    </HStack>
                ) : (
                    <Text fontWeight="bold" fontSize="lg">
                        {message.email}
                    </Text>
                )}
                <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    aria-label="Delete"
                    onClick={() => onDeleteClick(message._id)}
                />
            </Flex>
            <Text fontSize="md" mb={2}>
                <strong>Message:</strong> {message.message}
            </Text>
            <Text fontSize="md" mb={2}>
                <strong>Reason:</strong> {message.reason}
            </Text>
            <Text fontSize="md" mb={2}>
                <strong>Date:</strong> {new Date(message.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}
            </Text>
            {message.answered ? (


                <Flex alignItems="center" mb={2}>
                    <Tooltip label={message.response} aria-label="Answered" >

                        <Text color="green.500" fontSize="md" mb={0} cursor={"default"}>
                            <strong>Answered:</strong> Yes
                        </Text>
                    </Tooltip>
                    <CheckIcon color="green.500" ml={2} />


                </Flex>

            ) : (
                <Box mt={4}>
                    <Button color="white"
                        bg="main.primary"
                        _hover={{ bg: 'main.secondary' }} onClick={() => onReplyClick(message)}>
                        Reply
                    </Button>
                </Box>
            )}
        </Box>
    );
}