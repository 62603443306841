import {
  Badge,
  Box,
  Button,
  Code,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  VStack,
  useMediaQuery,
  useColorModeValue,



} from "@chakra-ui/react";

import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { AiOutlineLike, AiOutlineStar } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon, StarIcon } from "@chakra-ui/icons";
import AuthContext from "../AuthContext";
import BottomBar from "../components/BottomBar";
import request from "../api";
import VerifyEMailComponent from "../components/VerifyEMailComponent";
import CourseTag from "../components/CourseTag";
import { MdClass } from "react-icons/md";
import { TbCircleFilled, TbSchool, TbTriangleFilled } from "react-icons/tb";
import { BsBook } from "react-icons/bs";
import { IoSchoolOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { nameTranslation } from '../components/utils/Utils';
import { use } from "i18next";
import TutorialPopover from "../components/TutorialPopover";

const CourseBox = ({ course, onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      m={1}
      borderRadius={"md"}
      shadow={"md"}
      p={2}
    >
      <Text
        fontSize="sm"
        fontWeight="bold"
        mb={2}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {nameTranslation(course.prefix, i18n.language)} <Badge>{nameTranslation(course.reason, i18n.language)}</Badge>
      </Text>
      <Box
        p={4}
        bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
        _hover={{ bg: "main.secondary" }}
        border={"1px"}
        borderColor={"gray.200"}
        borderRadius="lg"
        display="flex"
        alignItems="center"
        onClick={() => onClick(course.id)}
        cursor="pointer"
        minW="400px"
        height="150px"
      >
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Stack direction="row" alignItems="center">
            <Text fontSize="md" fontWeight="bold">
              {nameTranslation(course.name, i18n.language)}
            </Text>
            <Spacer />
          </Stack>
          <Text fontStyle="italic">{nameTranslation(course.department.name, i18n.language)}</Text>
          <Flex direction="row" flexWrap="wrap" mt={2}>
            {course.tags.map((tag) => (
              <Tag
                key={tag}
                size="sm"
                mr={2}
                mb={2}
                colorScheme="red"
                borderRadius="full"
              >
                {tag
                  .replace("SS", "SUMMER")
                  .replace("WS", "WINTER")
                  .replace(/_/g, " ")}
              </Tag>
            ))}
          </Flex>
        </Flex>

        <Spacer />

        <VStack width="auto" align="flex-end">
          <Code colorScheme="blue" fontSize="sm" fontWeight="bold" >
            {course.code}
          </Code>
          <Code colorScheme="green" fontSize="sm" fontWeight="bold">
            {course.credits} ECTS
          </Code>

          <HStack justify="flex-end">
            <Text fontSize="sm" minWidth="max-content">
              👍{course.wouldRecommend != null ? Math.round(course.wouldRecommend * 100) : "-"} %
            </Text>
          </HStack>

          {/* <Text fontSize="sm" minWidth="max-content">
            ⭐️ {course.averageGrade ? course.averageRating.toFixed(1) : "-"}
            </Text> */}
          <Box minWidth="max-content" justify="flex-end">
            {Array.from({ length: 5 }, (_, index) => (
              <StarIcon
                size={"md"}
                key={index}
                color={index < course.averageRating?.toFixed(0) ? "yellow.400" : "gray.300"}
              />
            ))}
          </Box>

        </VStack>

      </Box>
    </Box>
  );
};

function Home() {
  const { t, i18n } = useTranslation();
  const [recommendations, setRecommendations] = useState([]);
  const [topRatedCourses, setTopRatedCourses] = useState([]);
  const [mostRecommendedCourses, setMostRecommendedCourses] = useState([]);
  const [moduleTree, setModuleTree] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const { isSignedIn, role } = useContext(AuthContext);
  const [tutorialModelOpen, setTutorialModelOpen] = useState(false);
  const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (parseInt(urlParams.get("tutorial")) === 0) {
      setTutorialModelOpen(true);
    }
    fetchRecommendations();
    fetchMostRecommended();
  }, []);
  useEffect(() => {
    if (isSignedIn) {
      fetchModuleTree();
      fetchUser();
    }
  }, [isSignedIn]);


  const handleSignIn = () => {
    const currentPath = window.location.pathname;
    navigate(`/sign-in?redirect=${encodeURIComponent(currentPath)}`);
  };
  const fetchUser = async () => {
    try {
      const user = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user-profile`,
        "GET"
      );
      setUser(user);
    } catch (error) {
      console.error("Failed to fetch study programs:", error);
    }
  };

  const fetchRecommendations = async () => {
    try {
      const recommendations = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/home/recommendations`,
        "GET"
      );
      setRecommendations(
        recommendations.courses.length > 0 ? recommendations.courses : []
      );
    } catch (error) {
      console.error("Failed to fetch study programs:", error);
    }
  };

  const fetchMostRecommended = async () => {
    try {
      const mostRecommendedCourses = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/home/most-recommended`,
        "GET"
      );
      setMostRecommendedCourses(mostRecommendedCourses.courses);
    } catch (error) {
      console.error("Failed to fetch study programs:", error);
    }
  };

  const fetchModuleTree = async () => {

    try {
      const moduleTree = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/home/course-tree`,
        "GET"
      );
      setModuleTree(moduleTree.tree.topLevelNodes);
    } catch (error) {
      console.error("Failed to fetch module tree", error);
    }
  };

  const openCoursePage = async (courseID) => {
    navigate(`/course/${courseID}`);
  };
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  return (
    <Flex direction="column" minH="100vh">
      <Navbar
      />
      <TutorialPopover
        childComponent={
          <Box />
        }
        stepNumber={0}
        skipSteps={!isLargerThan768 ? 1 : 0}

      />

      <Box mt={20} maxW={"100vw"}>
        {isSignedIn && allowedRoles.includes(role) && <VerifyEMailComponent />}
        <Box mx={isLargerThan768 ? 10 : 5} justify={"center"} mb={5}>
          <HStack spacing={10} justify={"left"}>
            <Box
              mt={4}
              p={4}
              bg={useColorModeValue(
                "main.elementBgLight",
                "main.elementBgDark"
              )}
              borderRadius={"lg"}
              shadow={"md"}
            >
              {isSignedIn && allowedRoles.includes(role) ? (
                <Heading>
                  {t("hello_user", { username: user.username })}
                </Heading>
              ) : (
                <Heading>{t("welcome_stranger")}</Heading>
              )}
              <Spacer />
            </Box>
          </HStack>

          <HStack justify={"left"}>
            <Text mt={4} fontSize="xl" fontWeight="bold">
              {t("popular_courses")}
            </Text>
            <Spacer />
          </HStack>

          <TutorialPopover
            childComponent={
              <SimpleGrid>
                {mostRecommendedCourses.length > 0 ? (
                  <VStack>
                    <HStack mt={4}>
                      {mostRecommendedCourses
                        .slice(0, isLargerThan768 ? 3 : 2)
                        .map((course) => (
                          <CourseTag course={course} truncate={!isLargerThan768} />
                        ))}
                    </HStack>

                    <HStack mt={2}>
                      {mostRecommendedCourses
                        .slice(isLargerThan768 ? 3 : 2, isLargerThan768 ? 6 : 4)
                        .map((course) => (
                          <CourseTag course={course} truncate={!isLargerThan768} />
                        ))}
                    </HStack>
                  </VStack>
                ) : (
                  <VStack>
                    <HStack mt={4} spacing={5}>
                      {Array.from(Array(isLargerThan768 ? 3 : 2).keys()).map(
                        (id) => (
                          <CourseTag isLoading />
                        )
                      )}
                    </HStack>

                    <HStack mt={4} spacing={5}>
                      {Array.from(Array(2).keys()).map((id) => (
                        <CourseTag isLoading />
                      ))}
                    </HStack>
                  </VStack>
                )}
              </SimpleGrid>
            }
            stepNumber={5}
          />

          <HStack justify={"left"}>
            <TutorialPopover
              childComponent={
                <Text mt={4} fontSize="xl" fontWeight="bold">
                  {isSignedIn && allowedRoles.includes(role)
                    ? t("personal_recommendations")
                    : t("most_recommended")}
                </Text>
              }
              stepNumber={6}
            />
            <Spacer />
          </HStack>

          <HStack overflowX="scroll" mt={2} r={0} mr={0}>
            {recommendations.map((course) => (
              <CourseBox course={course} onClick={openCoursePage} />
            ))}
          </HStack>

          {isSignedIn && allowedRoles.includes(role) ? (
            <>
              <Heading mt={6}>
                🎓{" "}
                {user.degreeProgram?.degreeType === "MASTER"
                  ? t("master_degree", {
                    degreeName: [nameTranslation(user.degreeProgram?.name, i18n.language)],
                  })
                  : user.degreeProgram?.degreeType === "MASTER" ? t("bachelor_degree", {
                    degreeName: [nameTranslation(user.degreeProgram?.name, i18n.language)],
                  }) : "N/A"}
              </Heading>

              <TutorialPopover
                childComponent={
                  <Box
                    mt={4}
                    bg={useColorModeValue(
                      "main.elementBgLight",
                      "main.elementBgDark"
                    )}
                    borderRadius={"lg"}
                    shadow={"md"}
                    p={isLargerThan768 ? 4 : 0}
                  >
                    <Tree nodes={moduleTree} />
                  </Box>
                }
                stepNumber={7}
                lastStep={true}
              />
            </>
          ) : (
            <Flex flex="1" alignItems="center" justifyContent="center" mt={2}>
              <Box alignItems="center" mt={15} textAlign="center">
                <Text fontSize="2xl" fontWeight="bold">
                  {t("see_course_tree")}
                </Text>
                <Text fontSize="xl" fontWeight="bold">
                  {t("sign_in_or_create_account")}
                </Text>
                <Flex justifyContent="center" mt={5}>
                  <Button
                    borderColor="main.primary"
                    variant="outline"
                    mr={2}
                    color="main.primary"
                    onClick={handleSignIn}
                  >
                    {t("sign_in")}
                  </Button>
                  <Button
                    bg="main.primary"
                    variant="solid"
                    _hover={{ bg: "main.secondary" }}
                    color="white"
                    onClick={() => {
                      navigate(`/sign-up`);
                    }}
                  >
                    {t("sign_up")}
                  </Button>
                </Flex>
              </Box>

            </Flex>

          )}
        </Box>

      </Box>
      <Spacer />
      <BottomBar />
    </Flex>
  );
}

const Tree = ({ nodes }) => {
  return (
    <Box>
      {nodes.map((node) => (
        <TreeNode key={node.name.en} node={node} />
      ))}
    </Box>
  );
};

const TreeNode = ({ node }) => {
  const navigate = useNavigate();
  const [showChildren, setShowChildren] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const toggleNested = () => {
    setShowChildren(!showChildren);
  };
  const { t, i18n } = useTranslation();

  return (
    <Box maxW={"100%"} pb={1}>
      {(node.nodeType === "RULE" || node.nodeType === "PART_OF_PROGRAMME") &&
        node.children.length !== 0 && (
          <HStack
            onClick={toggleNested}
            display="flex"
            alignItems="center"
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
            scale={1.2} // Example scale factor, adjust as needed
            cursor={"pointer"}
            py={1}
            _hover={{
              bg: useColorModeValue("gray.100", "gray.600"), // Changes background on hover for light and dark mode
              borderRadius: "md",
            }}


          >
            <Icon as={showChildren ? ChevronDownIcon : ChevronRightIcon} mr={0} boxSize={6} />
            <Icon as={BsBook} mr={2} color="red.500" boxSize={6} />
            <Text
              whiteSpace={showChildren ? "normal" : "nowrap"}
              overflow="hidden"
              textOverflow="ellipsis"
              maxW={"100%"}
              fontSize="md" // Example larger font size
              fontWeight={"bold"}
            >
              {node.name?.en}
            </Text>
            <Spacer />
          </HStack>
        )}

      {node.nodeType === "MODULE" && (
        <HStack>
          <Flex
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
            w="100vw"
            cursor="pointer"
            py={1}
            onClick={() => {
              navigate(`/course/${node.course}`);
            }}
            _hover={{
              bg: useColorModeValue("gray.100", "gray.600"), // Changes background on hover for light and dark mode
              borderRadius: "md",
            }}
          >
            {isLargerThan768 ? (
              <>
                <Icon
                  as={IoSchoolOutline}
                  mr={2}
                  color="main.primary"
                  boxSize={5}
                />
                <Code colorScheme="blue" fontSize="sm" mr={2}>
                  {node.courseCode}
                </Code>
                <Text
                  fontSize="lg"
                  mr={2}
                  maxW={"50%"}
                >
                  {nameTranslation(node.name, i18n.language)}
                </Text>
                <Spacer />
                {node?.embeddedCourseData.ratingCount > 0 ? (
                  <HStack>
                    <Text fontSize="md" fontWeight={"bold"} mr={2}>
                      ⭐️{" "}
                      {node?.embeddedCourseData?.averageRating.toFixed(1)
                        || " - "}
                    </Text>
                    <Text fontSize="md" fontWeight={"bold"} mr={2}>
                      👍{" "}
                      {node?.embeddedCourseData?.wouldRecommend != null
                        ? node.embeddedCourseData.wouldRecommend * 100
                        : " - "}{" "}
                      %
                    </Text>
                    <Text fontSize="md" fontWeight={"bold"} mr={2}>
                      🎓{" "}
                      {node?.embeddedCourseData?.averageGrade && node?.embeddedCourseData?.ratingCount > 1
                        ? node.embeddedCourseData.averageGrade.toFixed(1)
                        : " - "}
                    </Text>
                  </HStack>
                ) : (
                  <Text fontSize="md" fontStyle={"italic"} mr={2}>
                    {t("no_ratings_yet")}
                  </Text>
                )}
              </>
            ) : (
              <>
                <Icon
                  as={IoSchoolOutline}
                  mr={2}
                  color="main.primary"
                  boxSize={5}
                />
                <Box>
                  <Code colorScheme="blue" fontSize="sm" mr={2}>
                    {node.courseCode}
                  </Code>
                  <Spacer />
                </Box>
                <Box>
                  <Text fontSize="md" mr={2}>
                    {nameTranslation(node.name, i18n.language)}
                  </Text>
                  {node?.embeddedCourseData.ratingCount > 0 ? (
                    <HStack>
                      <Text fontSize="md" fontWeight={"bold"} mr={2}>
                        👍{" "}
                        {node?.embeddedCourseData?.wouldRecommend != null
                          ? node.embeddedCourseData.wouldRecommend * 100
                          : " - "}{" "}
                        %
                      </Text>
                      <Text fontSize="md" fontWeight={"bold"} mr={2}>
                        🎓{" "}
                        {node?.embeddedCourseData?.averageGrade && node?.embeddedCourseData?.ratingCount > 1
                          ? node.embeddedCourseData.averageGrade.toFixed(1)
                          : " - "}
                      </Text>
                      <Text fontSize="md" fontWeight={"bold"} mr={2}>
                        ⭐️{" "}
                        {node?.embeddedCourseData?.ratingCount
                          ? node.embeddedCourseData.ratingCount
                          : " - "}
                      </Text>
                    </HStack>
                  ) : (
                    <Text fontSize="md" fontStyle={"italic"} mr={2}>
                      {t("no_ratings_yet")}
                    </Text>
                  )}{" "}
                </Box>
              </>
            )}
          </Flex>
        </HStack>
      )}
      {showChildren && node.children && (
        <Box paddingLeft="40px">
          {
            node.children.filter(child => child !== null).map((child) => (
              <TreeNode key={child.name?.en} node={child} />
            ))}
        </Box>
      )}
    </Box>
  );
};
export default Home;

{/* <Box key={node.name.en} maxW={"100%"} pb={1}>
{(node.nodeType === "RULE" || node.nodeType === "PART_OF_PROGRAMME") &&
  node.children.length !== 0 && (
    <HStack
      onClick={toggleNested}
      display="flex"
      alignItems="center"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      scale={1.2} // Example scale factor, adjust as needed
      cursor={"pointer"}
      py={1}
      _hover={{
        bg: useColorModeValue("gray.100", "gray.700"), // Changes background on hover for light and dark mode
        borderRadius: "md",
      }}

    >
      <Icon as={showChildren ? ChevronDownIcon : ChevronRightIcon} mr={2} boxSize={6} />
      <Icon as={BsBook} mr={2} color="red.500" boxSize={6} />
      <Text
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxW={"100%"}
        fontSize="lg" // Example larger font size
        fontWeight={"bold"}
      // color={useColorModeValue("gray.600", "main.primary")}
      >
        {node.name.en}
      </Text>
      <Spacer />
    </HStack>
  )}

{node.nodeType === "MODULE" && (
  <HStack>
    <Flex
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      w="100vw"
      cursor="pointer"
      onClick={() => {
        navigate(`/course/${node.course}`);
      }}
      _hover={{
        bg: useColorModeValue("gray.100", "gray.700"), // Changes background on hover for light and dark mode
        borderRadius: "md",
      }}
    >
      <Icon as={IoSchoolOutline} mr={2} color="main.primary" boxSize={6} />
      <Box>
        <Code colorScheme="blue" fontSize="md" mr={2}>
          {node.courseCode}
        </Code>
        <Spacer />
      </Box>
      <Box>
        <Text fontSize="lg" mr={2}>
          {nameTranslation(node.name, i18n.language)}
        </Text>
        {node?.embeddedCourseData.ratingCount > 0 ? (
          <HStack>
            <Text fontSize="lg" fontWeight="bold" mr={2}>
              ⭐️{" "}
              {node?.embeddedCourseData?.averageRating.toFixed(1) || " - "}
            </Text>
            <Text fontSize="lg" fontWeight="bold" mr={2}>
              👍{" "}
              {node?.embeddedCourseData?.wouldRecommend != null
                ? node.embeddedCourseData.wouldRecommend * 100
                : " - "}{" "}
              %
            </Text>
            <Text fontSize="lg" fontWeight="bold" mr={2}>
              🎓{" "}
              {node?.embeddedCourseData?.averageGrade
                ? node.embeddedCourseData.averageGrade.toFixed(1)
                : " - "}
            </Text>
          </HStack>
        ) : (
          <Text fontSize="lg" fontStyle="italic" mr={2}>
            {t("no_ratings_yet")}
          </Text>
        )}
      </Box>
    </Flex>
  </HStack>
)}

{showChildren && node.children && (
  <Box paddingLeft="40px">
    {node.children.map((node) => (
      <TreeNode key={node.name.en} node={node} />
    ))}
  </Box>
)}
</Box> */}