
import React from 'react';
import { useState, useEffect } from 'react';
import { MultiSelect, useMultiSelect } from 'chakra-multiselect';

export default function ResponsibleMultiSelect({ responsibles,selectedResponsibles ,setSelectedResponsibles }) {

    if (responsibles == null) {
        return null;
      }
      if (selectedResponsibles == null) {
        selectedResponsibles = [];
      }
    const { value, options, onChange } = useMultiSelect({
        value: selectedResponsibles,
        options: responsibles,
      });
      useEffect(() => {
        if (value != [] || value != null)
        setSelectedResponsibles(value);

    }, [value]);
      return (
        <MultiSelect
        	
          options={options}
          value={value}
          placeholder="Select or create Responsibles"
          onChange={onChange}
          create
         
        />
      );
    }
