import { Outlet, Navigate } from 'react-router-dom'
import { useContext } from "react";
import AuthContext from "./AuthContext";

const PrivateRoutes = ({requiredRoles, redirect}) => {
    const role = localStorage.getItem('role');
    return(
       requiredRoles.includes(role)? <Outlet/> : <Navigate to={redirect}/>
    )
}

export default PrivateRoutes