import React from 'react';
import {
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import request from '../api';
export default function DeleteEditButtons({
  typeName,
  setIsEditing,
  route,
  fetchData,
  fetchCourseData,
  isAuthor,
  updateUser,
  setUpdateUser
}) {
  const text = '';
  //   const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [content, setContentText] = useState(text);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${route}`,
        'DELETE'
      );
      setUpdateUser(!updateUser);
  
      fetchData();
      if (fetchCourseData != null) fetchCourseData();
      toast({
        title:
          typeName.charAt(0).toUpperCase() + typeName.slice(1) + ' deleted',
        description: 'bruh...',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Damn, something went wrong...',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      console.error(error);
    }
  };
  return (
    <>
      {' '}
      <>
        <IconButton
          aria-label={'Delete ' + { typeName }}
          icon={<FaTrash />}
          size="xs"
          variant="ghost"
          color="gray.500"
          _hover={{ color: 'red.500' }}
          onClick={() => setDeleteModalOpen(true)}
        />

        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Delete {typeName.charAt(0).toUpperCase() + typeName.slice(1)}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this {typeName}?
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => handleDelete()}>
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        {isAuthor&&<IconButton
          aria-label={'Edit ' + { typeName }}
          icon={<FaEdit />}
          size="xs"
          variant="ghost"
          color="gray.500"
          _hover={{ color: 'green.500' }}
          onClick={() => setIsEditing(true)}
        />}
      </>
    </>
  );
}

export async function editElement(editedText, route, typeName, toast) {
  if (editedText.trim() == '') return;
  try {
    const response = await request(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${route}`,
      'PUT',
      { text: editedText.trim() }
    );
    toast({
      title: typeName.charAt(0).toUpperCase() + typeName.slice(1) + ' edited',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    return true;
  } catch (error) {
    toast({
      title: 'Damn, something went wrong...',
      description: error.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    console.error(error);
    return false;
  }
}
