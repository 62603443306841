import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { CustomTheme } from './CustomTheme';
import { ColorModeScript, theme } from "@chakra-ui/react"

function ChakraTheme(props) {
  return (
    <ChakraProvider theme={CustomTheme}>
       <ColorModeScript initialColorMode={'system'} />
        {props.children}
    </ChakraProvider>
  )
}

export default ChakraTheme