import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    SimpleGrid,
    Switch,
    Text,
    useToast
} from "@chakra-ui/react";
import AreaSingleSelect from "./utils/AreaSingleSelect";
import ResponsibleMultiSelect from "./utils/ResponsibleMultiSelect";
import React, {useEffect, useState} from "react";
import request from "../api";
import {useNavigate} from "react-router-dom";
import {isNumber} from "chart.js/helpers";

export default function CreateCourseModal({onClose}) {


    const toast = useToast();
    const navigate = useNavigate();
    const [areas, setAreas] = useState(null);
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [selectedArea, setSelectedArea] = useState('');
    const [responsibles, setResponsibles] = useState(null);
    const [courseCode, setCourseCode] = useState('');
    const [courseName, setCourseName] = useState('')
    const [isBonus, setIsBonus] = useState(false);
    const [semesterOptions, setSemesterOptions] = useState([]);
    const [credits, setCredits] = useState(0);
    const [levelOptions, setLevelOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [courseWebsite, setCourseWebsite] = useState('');

    const [courseCodeError, setCourseCodeError] = useState(false);
    const [courseNameError, setCourseNameError] = useState(false);
    const [areaError, setAreaError] = useState(false);
    const [semesterError, setSemesterError] = useState(false);
    const [creditsError, setCreditsError] = useState(false);
    const [levelError, setLevelError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [languageError, setLangaugeError] = useState(false);
    const [responsiblesError, setResponsiblesError] = useState(false);

    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    const inputInvalid = courseCodeError || courseNameError || areaError || semesterError
        || creditsError || levelError || websiteError || languageError || responsiblesError
    const validateInput = () => {
        courseCode.length === 0 ? setCourseCodeError(true) : setCourseCodeError(false);
        courseName.trim().length === 0 ? setCourseNameError(true) : setCourseNameError(false);
        selectedArea === '' ? setAreaError(true) : setAreaError(false);
        semesterOptions.length === 0 ? setSemesterError(true) : setSemesterError(false);
        !credits || credits <= 0 || credits > 30 || !isNumber(credits) ? setCreditsError(true) : setCreditsError(false);
        levelOptions.length === 0 ? setLevelError(true) : setLevelError(false);
        languageOptions.length === 0 ? setLangaugeError(true) : setLangaugeError(false);
        selectedResponsibles.length === 0 ? setResponsiblesError(true) : setResponsiblesError(false);
        courseWebsite.trim().length === 0 || !urlPattern.test(courseWebsite) ? setWebsiteError(true) : setResponsiblesError(false);
    }
    useEffect(() => {
        fetchAreas();
        fetchResponsibles();
    }, [])


    const handleCreateCourse = async () => {
        try {
            validateInput()
            if(!inputInvalid){
                const body = {
                    area: selectedArea,
                    courseName: courseName,
                    courseCode: courseCode,
                    hasBonus: isBonus,
                    offeredInSemesters: semesterOptions,
                    credits: credits,
                    moduleLevel: levelOptions,
                    languages: languageOptions,
                    website: courseWebsite,
                    responsibles: selectedResponsibles
                }
                const response = await request(
                    `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses`,
                    'POST', body);
                onClose();
                toast({
                    title: "Success!",
                    description: 'Course created successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
        }
    }

    const fetchAreas = async () => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/courseCode/areas`,
                'GET'
            );
            setAreas(response.areas);
        } catch (error) {
        }
    };

    const fetchResponsibles = async () => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/courseCode/responsibles`,
                'GET'
            );
            setResponsibles(response.responsibles);
        } catch (error) {
        }
    };

    return (
        <>
            <SimpleGrid templateColumns="auto auto" spacing={2} ml={2} mb={2}>
                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Course Code:
                </Text>
                <FormControl isInvalid={courseCodeError}>
                    <Input value={courseCode}
                           onChange={e => setCourseCode(e.target.value.trim())}
                    />
                    <FormErrorMessage>Course code must not be empty</FormErrorMessage>
                </FormControl>
                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Course Name:
                </Text>
                <FormControl isInvalid={courseNameError}>
                    <Input value={courseName}
                           onChange={e => setCourseName(e.target.value)}
                    />
                    <FormErrorMessage>Course name must not be empty</FormErrorMessage>
                </FormControl>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Area:
                </Text>
                <FormControl isInvalid={areaError}>
                    <AreaSingleSelect
                        areas={areas}
                        selectedArea={selectedArea}
                        setSelectedArea={setSelectedArea}
                    />
                    <FormErrorMessage>Please select area</FormErrorMessage>
                </FormControl>
                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Bonus
                </Text>
                <Switch onChange={e => setIsBonus(e.target.checked)}/>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Semester:
                </Text>
                <HStack spacing={2}>
                    <FormControl isInvalid={semesterError}>
                        <Checkbox
                            isChecked={semesterOptions?.includes('WS')}
                            onChange={e =>
                                setSemesterOptions(
                                    e.target.checked
                                        ? semesterOptions.concat('WS')
                                        : semesterOptions.filter(item => item !== 'WS')
                                )
                            }
                        >
                            Winter
                        </Checkbox>
                        <Checkbox
                            isChecked={semesterOptions?.includes('SS')}
                            onChange={e =>
                                setSemesterOptions(
                                    e.target.checked
                                        ? semesterOptions.concat('SS')
                                        : semesterOptions.filter(item => item !== 'SS')
                                )
                            }
                        >
                            Summer
                        </Checkbox>
                        <Checkbox
                            isChecked={semesterOptions?.includes('IRREGULARLY')}
                            onChange={e =>
                                setSemesterOptions(
                                    e.target.checked
                                        ? semesterOptions.concat('IRREGULARLY')
                                        : semesterOptions.filter(item => item !== 'IRREGULARLY')
                                )
                            }
                        >
                            Irregular
                        </Checkbox>
                        <FormErrorMessage>Please choose at least one semester option</FormErrorMessage>
                    </FormControl>
                </HStack>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Credits:
                </Text>
                <HStack spacing={2}>
                    <FormControl isInvalid={creditsError}>
                        <Input
                            value={credits}
                            onChange={e => setCredits(e.target.value.trim())}
                        />
                        <FormErrorMessage>Credits must be a number greater than zero</FormErrorMessage>
                    </FormControl>
                    <Box flex={1}/>
                </HStack>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Level:
                </Text>
                <HStack spacing={2}>
                    <FormControl isInvalid={levelError}>
                        <Checkbox
                            isChecked={levelOptions?.includes('BACHELOR')}
                            onChange={e =>
                                setLevelOptions(
                                    e.target.checked
                                        ? levelOptions.concat('BACHELOR')
                                        : levelOptions.filter(item => item !== 'BACHELOR')
                                )
                            }
                        >
                            Bachelor
                        </Checkbox>
                        <Checkbox
                            isChecked={levelOptions?.includes('MASTER')}
                            onChange={e =>
                                setLevelOptions(
                                    e.target.checked
                                        ? levelOptions.concat('MASTER')
                                        : levelOptions.filter(item => item !== 'MASTER')
                                )
                            }
                        >
                            Master
                        </Checkbox>
                        <FormErrorMessage>Please choose at least course level</FormErrorMessage>
                    </FormControl>
                </HStack>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Language:
                </Text>
                <HStack spacing={2}>
                    <FormControl isInvalid={languageError}>
                        <Checkbox
                            isChecked={languageOptions?.includes('GERMAN')}
                            onChange={e =>
                                setLanguageOptions(
                                    e.target.checked
                                        ? languageOptions.concat('GERMAN')
                                        : languageOptions.filter(item => item !== 'GERMAN')
                                )
                            }
                        >
                            German
                        </Checkbox>
                        <Checkbox
                            isChecked={languageOptions?.includes('ENGLISH')}
                            onChange={e =>
                                setLanguageOptions(
                                    e.target.checked
                                        ? languageOptions.concat('ENGLISH')
                                        : languageOptions.filter(item => item !== 'ENGLISH')
                                )
                            }
                        >
                            English
                        </Checkbox>
                        <FormErrorMessage>Please choose at least one language</FormErrorMessage>
                    </FormControl>
                </HStack>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Responsibles:
                </Text>
                <FormControl isInvalid={responsiblesError}>
                    <ResponsibleMultiSelect
                        responsibles={responsibles}
                        selectedResponsibles={selectedResponsibles}
                        setSelectedResponsibles={setSelectedResponsibles}
                    />
                    <FormErrorMessage>Please choose at least one responsible</FormErrorMessage>
                </FormControl>

                <Text fontSize="large" fontWeight="bold" mr={2}>
                    Course Website:
                </Text>
                <FormControl isInvalid={websiteError}>
                    <Input
                        value={courseWebsite}
                        onChange={e => setCourseWebsite(e.target.value.trim())}
                    />
                    <FormErrorMessage>Course website must be a valid url</FormErrorMessage>
                </FormControl>
                <></>
                <Button
                    alignSelf={'end'}
                    bg="main.primary"
                    mr={2}
                    color="white"
                    onClick={handleCreateCourse}
                >
                    Create course
                </Button>
            </SimpleGrid>
        </>
    );
}