import React from "react"; 
import ResetPasswordComponent from "../components/ResetPasswordComponent"; 
import AuthLayout from "../components/layouts/AuthLayout";


function ResetPassword() {
  return (

    <AuthLayout>
    <ResetPasswordComponent />
  </AuthLayout>
  
  );

}

export default ResetPassword;