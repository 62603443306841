import React, { useEffect, useState } from "react";
import { Spinner, Center, CheckIcon, Stack, Flex, Text, Box, Heading, useColorModeValue, Link, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { MdCheckCircle, MdLaptopWindows } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import request from "../api";
function VerifyEmail() {

    const [result, setResult] = useState("");
    const [verifying, setVerifying] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token");

    const verifyEmail = async () => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/verify-email`,
                "POST",
                {

                    token: token

                },
            );
            console.log("Response: " + response);
            if (response.message) {

                setResult("Successful: " + response.message);
                setIsVerified(true);

                setTimeout(() => {
                    window.location.href = "/";
                }, 2000)
            } else if (response.error) {

                setResult("Error: " + response.error)
                setResult("Unexpected result from backend")
                setTimeout(() => {
                    window.location.href = "/"
                }, 2000)
            } else {

                setResult("Unexpected result from backend")
                setTimeout(() => {
                    window.location.href = "/"
                }, 2000)
            }
            setVerifying(false);
        }

        catch (error) {
            console.log(error);
            setResult("Unexpected error")
            setVerifying(false);
            setTimeout(() => {
                window.location.href = "/"
            }, 2000)
        }
    }

    useEffect(() => {
        if (token) {
            verifyEmail();
        } else {
            setVerifying(false);
        }
    }
        , []);

    if (verifying) {
        return (
            <Center height="100vh">
                <div>Verifying...</div>
                <Spinner size="xl" color="main.primary" />
            </Center>
        );
    }

    if (isVerified) {
        return (
            <Center height="100vh" flexDirection="column">
                <MdCheckCircle size={64} color="green" />
                <Text>Verified. You can now proceed.</Text>
            </Center>
        );
    }

    return (
        <Center height="100vh">
            <Text> {result}</Text>
        </Center>

    );
}

export default VerifyEmail;