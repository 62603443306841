import React, { useEffect, useState } from "react";
import {
    Box, Button, Flex,
    FormControl,
    FormLabel,
    Heading, Input,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select, Text, Textarea, useToast, useColorModeValue
} from "@chakra-ui/react";
import UserMessage from "./UserMessage";
import request from "../api";

export default function UserMessageList({ messages, fetchUserMessages }) {

    const [sortBy, setSortBy] = useState('date');
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [replyMessage, setReplyMessage] = useState('');
    const [replySubject, setReplySubject] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [displayedMessages, setDisplayedMessages] = useState(10);
    const [totalMessagesCount, setTotalMessagesCount] = useState(0);
    const toast = useToast();

    useEffect(() => {
        setTotalMessagesCount(messages.length);
    }, [messages]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleReplyClick = (message) => {
        setSelectedMessage(message);
        setReplyModalOpen(true);
    };

    const onDeleteClick = async (messageId) => {
        try {
            await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/support/messages/${messageId}`, "DELETE");
            fetchUserMessages();
        } catch (error) {
        }
    }

    const handleReplySubmit = async () => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/support/send-reply`, "POST", {
                email: selectedMessage.email,
                reason: selectedMessage.reason,
                userMessage: selectedMessage.message,
                responseMessage: replyMessage,
                id: selectedMessage._id
            })
            setReplyModalOpen(false);
            fetchUserMessages();

        } catch (error) {
            toast({
                title: 'Error',
                description: `Error while sending reply. Please try again`,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

    };


    const sortedMessages = messages.sort((a, b) => {
        if (sortBy === 'date') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortBy === 'email') {
            return a.email.localeCompare(b.email);
        } else if (sortBy === 'messageType') {
            return a.reason.localeCompare(b.reason);
        }
        return 0;
    });

    const filteredMessages = sortedMessages.filter(
        (message) => message.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const displayedSortedMessages = filteredMessages.slice(0, displayedMessages);

    const loadMoreMessages = () => {
        if (displayedMessages + 10 <= filteredMessages.length) {
            setDisplayedMessages(displayedMessages + 10);
        } else {
            setDisplayedMessages(filteredMessages.length);
        }
    };

    return (
        <Box p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Heading as="h1" size="xl">
                    User Messages
                </Heading>
                <FormControl width="fit-content">
                    <FormLabel>Sort By</FormLabel>
                    <Select size="sm" value={sortBy} onChange={handleSortChange}>
                        <option value="date">Date</option>
                        <option value="email">Email</option>
                        <option value="messageType">Type</option>
                    </Select>
                </FormControl>
            </Flex>
            <Input
                placeholder="Search by username or email"
                value={searchQuery}
                onChange={handleSearchInputChange}
                mb={4}
            />
            {displayedSortedMessages.map((message) => (
                <UserMessage
                    key={message.id}
                    message={message}
                    onReplyClick={handleReplyClick}
                    onDeleteClick={onDeleteClick}
                />
            ))}
            <Modal isOpen={replyModalOpen} onClose={() => setReplyModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Reply to {selectedMessage && selectedMessage.email}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel mb="0" >Reason</FormLabel>
                        <Text>{selectedMessage && selectedMessage.reason}</Text>
                        <FormLabel mb="0" mt={2}>Message</FormLabel>
                        <Text>{selectedMessage && selectedMessage.message}</Text>

                        <FormControl mt={4}>
                            <FormLabel>Response</FormLabel>
                            <Textarea
                                value={replyMessage}
                                onChange={(e) => setReplyMessage(e.target.value)}
                                placeholder="Enter your reply"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="white"
                            bg="main.primary"
                            _hover={{ bg: 'main.secondary' }} mr={3} onClick={handleReplySubmit}>
                            Send Reply
                        </Button>
                        <Button variant="ghost" onClick={() => setReplyModalOpen(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Text>Showing {displayedSortedMessages.length} of {totalMessagesCount} reports</Text>
            {totalMessagesCount > displayedMessages && <Button onClick={loadMoreMessages} color="white"
                bg="main.primary"
                _hover={{ bg: 'main.secondary' }} mt={4}>
                Load More
            </Button>}
        </Box>
    );
}