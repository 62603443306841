import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, VStack, Link, Flex , Spacer} from '@chakra-ui/react';
import BottomBar from '../components/BottomBar';
import Navbar from '../components/Navbar';
import { useContext } from 'react';
import AuthContext from "../AuthContext";



const Imprint = () => {
    const { t } = useTranslation();
    const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
    const { isSignedIn, role } = useContext(AuthContext);
    return (
        <Flex direction="column" minH="100vh">
            <Navbar loggedIn={isSignedIn && allowedRoles.includes(role)} />

            <Box p={5} mt={20} maxW={"100vw"}>
                <VStack spacing={2} align="flex-start">
                    <Text fontWeight="bold">{t("imprint")}</Text>
                    <Text>{t("legal_information")}</Text>
                    <Text>{t("imprint_contact_name")}</Text>
                    <Text>{t("address")}</Text>
                    <Text>{t("country")}</Text>
                    <Text> </Text>
                    <Text> </Text>
                    <Text fontWeight={"bold"}
                    >{t("contact")}</Text>
                    <Text>{t("phone")}</Text>
                    <Text>{t("email") +":"} <Link href={`mailto:${t("imprint_contact_email")}`} color="teal.500">
                        {t("imprint_contact_email")}
                    </Link></Text>
                    <Text> </Text>
                    <Text> </Text>
                    <Text fontWeight={"bold"}>{t("content_responsibility")}</Text>
                    <Text>{t("responsible_person")}</Text>
                    <Text>{t("address_as_above")}</Text>
                </VStack>
            </Box>
            <Spacer />
            <BottomBar />
        </Flex>
    );
};

export default Imprint;