import React, { useState, useEffect, useColorMode } from "react";
import PageRouter from "./PageRouter";
import { AuthProvider } from "./AuthContext";
import CookieBanner from "./components/CookieBanner";

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import ChakraTheme from "./theme/ChakraTheme";
import DefaultHelmet from "./DefaultHelmet";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  // const { colorMode, toggleColorMode } = useColorMode();
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };
  return (
    <ChakraTheme>
      {/* <Elements stripe={stripePromise}> */}
      <CookieBanner/>
        <AuthProvider>
        <DefaultHelmet />
          <PageRouter />
        </AuthProvider>
      {/* </Elements> */}
    </ChakraTheme>
  );
}

export default App;
