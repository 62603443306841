import {
  Box,
  Flex,
  Stack,
  Text,
  HStack,
  Image,
  SimpleGrid,
  useColorModeValue,
  Link
} from "@chakra-ui/react";

import CountUpBox from "../utils/CountUpBox";
import { useTranslation } from "react-i18next";
import request from "../../api";
import { useState } from "react";
import { useEffect } from "react";

import ContactFormComponent from "../ContactFormComponent";

const MobileAuthLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stats, setStats] = useState({});
  const { t, i18n } = useTranslation();
  function changeLanguage() {
    i18n.changeLanguage(i18n.language == "de" ? "en" : "de");
  }
  useEffect(() => {
    fetchStats();

  }, []);
  const fetchStats = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/get-stats`,
        "GET"
      );
      setStats(response);
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <Box justifyContent="center">
      <Box
        padding="5"
        // display="flex"
        // flexDirection="column"
        justifyContent="center"
      >
        <Image src={useColorModeValue("/logo_light.svg", "/logo_dark.svg")} alt="classrate" width="600px" />

        <Text fontSize="2xl" mt="10" fontWeight="bold">
          Elevate Your Learning.
        </Text>
        <Text fontSize="2xl" mb="4" fontWeight="bold">
          Rate, Discover, Succeed.
        </Text>
        <Text fontSize="2xl" my="4" color={useColorModeValue("gray.900", "gray.500")}
        >
          {t("make_studies_easier")}
        </Text>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {children}
        </Box>
        {/* <HStack spacing={4} w="full" justifyContent="space-between" mt="10"> */}
        <SimpleGrid columns={3} spacing={4} w="full" mt={4}>

          <CountUpBox emoji="🎓" name={t("students")} count={stats.numberOfUsers} />
          <CountUpBox emoji="⭐️" name={t("ratings")} count={stats.numberOfRatings} />
          <CountUpBox emoji="💼" name={t("courses")} count={stats.numberOfCourses} />

        </SimpleGrid>

        <Flex
          width="100%"
          justify="center"
          align="center"
          // position="absolute"
          // bottom="0"
          my="10"
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Link

            fontSize="md"
            onClick={() => {
              setIsOpen(true);
            }}
            flex={1}
            cursor={"pointer"}
            textAlign="right"
          >
            {t("contact_us")}
          </Link>

          <Image
            src={i18n.language === "de" ? "/de.svg" : "/gb.svg"}
            alt={i18n.language.toUpperCase()}
            width="20px"
            borderRadius={"sm"}
            mx={4}
            cursor={"pointer"}
            onClick={changeLanguage}
          />

          <Link fontSize="md" cursor={"pointer"} href="/imprint" flex={1} >
            {t("imprint")}
          </Link>

          <ContactFormComponent isOpen={isOpen} setIsOpen={setIsOpen} />
        </Flex>
      </Box>
    </Box>
  );
};

export default MobileAuthLayout;
