import { useState, useEffect, useContext } from "react";
import {
  Stack,
  Heading,
  Text,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import request from "../api";
import { useSearchParams } from "react-router-dom";
import AuthContext from '../AuthContext';


export default function ResetPasswordComponent() {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const { isSignedIn, role } = useContext(AuthContext);
  const allowedRoles = ['ADMIN', "STUDENT", 'MODERATOR'];

  const toast = useToast()

  useEffect(() => {
    setPasswordMatch(password === repeatPassword);
  }, [password, repeatPassword]);

  async function handleResetPassword() {
    if (password.length < 8) {
      alert("Please enter a password with at least 8 characters.");
      return;
    }

    if (!passwordMatch) {
      alert("The passwords do not match. Please try again.");
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    try {
      const data = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/reset-password`, 'POST', {
        token: token,
        password: password,
      },

      );
      setSuccessMessage(data.message);
      window.location.href = '/';


    } catch (error) {
      toast({
        title: 'Damn, something went wrong...',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

  }

  // const urlParams = new URLSearchParams(window.location.search);



  return (

    <> {!isSignedIn && !allowedRoles.includes(role) ?

      <Flex height="100vh" bg="#E2E8F0" justifyContent="center" alignItems="center">

        <Box
          width="400px"
          padding="10"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginRight="60px"
          ml={14}
        >
          <Stack
            bg="white"
            rounded="xl"
            p={8}
            spacing={6}
            direction="column"
            shadow="lg"
            height="100%"
          >
            <Heading lineHeight={1.1} fontSize="4xl" color="gray.800">
              New password
            </Heading>
            <Text fontSize="md" color="gray.800">
              Set your new password
            </Text>

            <Box as="form">
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="password" color="gray.800">
                    Password
                  </FormLabel>
                  <Input
                    type="password"
                    placeholder="Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    borderColor="gray.800"
                    _placeholder={{ color: "gray.500" }}
                    id="password"
                    color="gray.800"
                    autoComplete="new-password"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="repeat password" color="gray.800">
                    Repeat Password
                  </FormLabel>
                  <Input
                    type="password"
                    placeholder="Repeat Password"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    borderColor="gray.800"
                    _placeholder={{ color: "gray.500" }}
                    id="repeatPassword"
                    color="gray.800"
                  />
                  {!passwordMatch && (
                    <p style={{ color: "red" }}>Passwords do not match.</p>
                  )}
                  {successMessage && (
                    <Text color="green.500">{successMessage}</Text>
                  )}
                  {error && <Text color="red.500">{error}</Text>}
                </FormControl>


                <Button
                  fontFamily="heading"
                  onClick={handleResetPassword}
                  disabled={!password || !repeatPassword}
                  bg="main.primary"
                  _hover={{ bg: "main.secondary" }}
                  color="white"
                  mt={4}
                >
                  Reset your password
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Flex>

      : window.location.href = "/"}
    </>
  );
}
