import React from "react";
import { Button, Flex, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const getPageNumbers = () => {
    const MAX_PAGE_ITEMS = isLargerThan768? 5: 1;
    const pages = [];

    if (totalPages <= MAX_PAGE_ITEMS) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      let startPage = currentPage - Math.floor(MAX_PAGE_ITEMS / 2);
      if (startPage < 1) {
        startPage = 1;
      }

      let endPage = startPage + MAX_PAGE_ITEMS - 1;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = endPage - MAX_PAGE_ITEMS + 1;
      }

      if (startPage > 1 && isLargerThan768) {
        pages.push(1, "...");
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages && isLargerThan768) {
        pages.push("...", totalPages);
      } else if (endPage < totalPages) {
        pages.push("/", totalPages);
      }
    }

    return pages;
  };

  return (
    <Flex justifyContent="center" mt={4}>
      <Button
        isDisabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        mr={2}
      >
        {t("previous")}
      </Button>
      {getPageNumbers().map((page, index) => (
        <Button
          key={index}
          variant={currentPage === page ? "solid" : "outline"}
          onClick={() => handlePageChange(page)}
          mx={1}
        >
          {page}
        </Button>
      ))}
      <Button
        isDisabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        ml={2}
      >
        {t("next")}
      </Button>
    </Flex>
  );
};
