import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultHelmet = () => (
  <Helmet>
    <title>Classrate - Rate and Discover University Courses</title>
    <meta
      name="description"
      content="Classrate - The ultimate platform for students to rate university courses, share experiences, and discover the best courses for their studies. Join the community and elevate your academic journey!"
    />
    <meta
      name="keywords"
      content="classrate, university, courses, ratings, students, education, reviews, academic, platform, studies, college, learning, experience"
    />
    <meta name="author" content="Classrate Team" />
    <meta property="og:title" content="Classrate - Rate and Discover University Courses" />
    <meta
      property="og:description"
      content="Classrate is the ultimate platform for students to rate university courses, share experiences, and find the best courses for their studies. Join the community and elevate your academic journey!"
    />
    <meta property="og:image" content="icon-512.png" />
    <meta property="og:url" content="https://classrate.de" />
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Classrate - Rate and Discover University Courses" />
    <meta
      name="twitter:description"
      content="Classrate is the ultimate platform for students to rate university courses, share experiences, and find the best courses for their studies. Join the community and elevate your academic journey!"
    />
    <meta name="twitter:image" content="icon-512.png" />
  </Helmet>
);

export default DefaultHelmet;
