import React from "react";
import {
  Box,
  Flex,
  Text,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  useMediaQuery,
} from "@chakra-ui/react";

export default function RateDetailSlider({
  title,
  lowerScale,
  upperScale,
  value,
  setValue,
}) {
  const handleSliderChange = (newValue) => {
    setValue(newValue);
  };
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Flex my={3}>
      <Box flex={1}>
        <Text fontSize={"md"}>{title}</Text>
        <Slider
          size={"lg"}
          value={value}
          onChange={(newValue) => handleSliderChange(newValue)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>

        <Box display="flex" justifyContent="space-between">
          <Text fontSize={isLargerThan768? "sm": "xs"} >{lowerScale}</Text>
          <Box flex="1" />
          <Text fontSize={isLargerThan768? "sm": "xs"} textAlign={"right"}>
            {upperScale}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
