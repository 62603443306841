import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DateEditedHStack({ date, edited}) {
  const { t, i18n } = useTranslation();
    const parsedDate = new Date(date).toLocaleDateString(i18n.language === "en"? "en-US" : "de-DE"
    , {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
  return (
    <HStack spacing={2}>
      <Text fontSize="sm" color="gray.500">
        {parsedDate}
      </Text>
      {edited && (
        <Text fontSize="sm" color="gray.500">
          {t("edited")}
        </Text>
      )}
    </HStack>
  );
}
