// CookieBanner.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Text, VStack, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  

  const { t } = useTranslation();

  

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookie-consent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      position="fixed"
      bottom="4"
      left="50%"
      transform="translateX(-50%)"
      width="90%"
      maxW="container.md"
      bg="gray.800"
      color="white"
      p={6}
      borderRadius="md"
      boxShadow="lg"
      zIndex={1000}
    >
      <VStack spacing={4}>
        <Text textAlign="center">
          {t('cookie_message')}
        </Text>
        <HStack spacing={4}>
          <Button
            bg="main.primary"
            color="white"
            variant="solid"
            _hover={{ bg: "main.secondary" }}
            onClick={handleAccept}
          >
            {t('accept')}
          </Button>
          <Button
            borderColor="main.primary"
            variant="ghost"
            color="main.primary"
            onClick={handleDecline}
          >
            {t('decline')}
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default CookieBanner;
