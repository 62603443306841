import {
  Box,
  Flex,
  Stack,
  Text,
  HStack,
  Image,
  SimpleGrid,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";

import CountUp from "react-countup";
import request from "../../api";
import Typewriter from "typewriter-effect";
import ContactFormComponent from "../ContactFormComponent";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState({});
  function changeLanguage() {
    i18n.changeLanguage(i18n.language == "de" ? "en" : "de");
  }
  useEffect(() => {
    fetchStats();

  }, []);

  const fetchStats = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/get-stats`,
        "GET"
      );
      setStats(response);
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <Flex direction="column" minH="100vh">
      <Flex height="100vh" position="relative" >

        <Box
          width="66%"
          padding="10"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Image
            src={useColorModeValue("/logo_light.svg", "/logo_dark.svg")}
            alt="classrate"
            width="600px"
          />
          <Text fontSize="5xl" fontWeight="bold" mt="4">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Elevate Your Learning")
                  .pauseFor(1500)
                  .deleteAll()
                  .typeString("Rate, Discover, Succeed.")
                  .start();
              }}
            />
          </Text>

          <Text
            fontSize="2xl"
            marginTop="8"
            color={useColorModeValue("gray.900", "gray.500")}
          >
            {t("make_studies_easier")}
            { }
          </Text>

          <HStack spacing={4} w="full" justifyContent="space-between" mt="10">
            <Box
              bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
              p={4}
              rounded="2xl"
              shadow="lg"
              flex="1"
            >
              <Text
                fontSize="lg"
                fontWeight="bold"
                textAlign="center"
                color={useColorModeValue("gray.900", "gray.500")}              >
                {t("students_with_emoji")}
              </Text>
              <Text
                fontSize="4xl"
                fontWeight="bold"
                textAlign="center"
              >
                <CountUp end={stats.numberOfUsers} useEasing={true} duration={1} />
              </Text>
            </Box>

            <Box
              bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
              p={4}
              rounded="2xl"
              shadow="lg"
              flex="1"
            >
              <Text
                fontSize="lg"
                fontWeight="bold"
                textAlign="center"
                color={useColorModeValue("gray.900", "gray.500")}              >
                {t("ratings_with_emoji")}
              </Text>
              <Text
                fontSize="4xl"
                fontWeight="bold"
                textAlign="center"
              >
                <CountUp end={stats.numberOfRatings} useEasing={true} duration={1} />
              </Text>
            </Box>

            <Box
              bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
              p={4}
              rounded="2xl"
              shadow="lg"
              flex="1"
            >
              <Text
                fontSize="lg"
                fontWeight="bold"
                textAlign="center"
                color={useColorModeValue("gray.900", "gray.500")}
              >
                {t("courses_with_emoji")}
              </Text>
              <Text
                fontSize="4xl"
                fontWeight="bold"
                textAlign="center"
              >
                <CountUp end={stats.numberOfCourses} useEasing={true} duration={1} />
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box
          px="5"
          display="flex"
          flexDirection="column"
        // justifyContent="center"
        >
          {children}
        </Box>
      </Flex>
      {/* <Spacer /> */}
      <Flex
        width="100%"
        justify="center"
        align="center"
        // position="absolute"
        // bottom="0"
        mb="10"
        alignContent={"center"}
        justifyContent={"center"}
      >
        <Link

          fontSize="md"
          onClick={() => {
            setIsOpen(true);
          }}
          flex={1}
          cursor={"pointer"}
          textAlign="right"
        >
          {t("contact_us")}
        </Link>

        <Image
          src={i18n.language === "de" ? "/de.svg" : "/gb.svg"}
          alt={i18n.language.toUpperCase()}
          width="20px"
          borderRadius={"sm"}
          mx={4}
          cursor={"pointer"}
          onClick={changeLanguage}
        />

        <Link fontSize="md" cursor={"pointer"} href="/imprint" flex={1} >
          {t("imprint")}
        </Link>

        <ContactFormComponent isOpen={isOpen} setIsOpen={setIsOpen} />
      </Flex>
    </Flex>
  );
};

export default AuthLayout;
