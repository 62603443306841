import React, { useState, useEffect } from "react";
import {
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
  Alert,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


export default function ContactFormComponent({ isOpen, setIsOpen }) {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const token = localStorage.getItem("authToken");
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [errors, setErrors] = useState("");
  const [charCount, setCharCount] = useState(0);

  const [signedInEmail, setSignedInEmail] = useState(false);

  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const options = [
    { label: t("general_question"), value: "a general question" },
    { label: t("technical_support"), value: "technical support" },
    { label: t("feedback"), value: "feedback" },
    { label: t("account_assistance"), value: "account assistence" },
    { label: t("report_content"), value: "reporting inappropriate content" },
    { label: t("other"), value: "wants to know about something else" },
  ];
  const toast = useToast();

  const handleSend = async (e) => {
    e.preventDefault();

    if (message.length > 1500) {
      return;
    }

    const emptyFields = [];

    if (!email.trim() && !token) {
      emptyFields.push("email");
    }

    if (!reason.trim()) {
      emptyFields.push("reason");
    }

    if (!message.trim()) {
      emptyFields.push("message");
    }

    if (emptyFields.length > 0) {
      setEmptyFields(emptyFields);
      return;
    }

    try {
      const params = {
        method: "POST",
        body: JSON.stringify({
          email: email,
          reason: reason,
          message: message,
        }),
      };
      params["headers"] = new Headers();
      params["headers"].append("Content-Type", "application/json");
      if (token) {
        params["headers"].append("Authorization", "Bearer " + token);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/support/send-request`,
        params
      );

      const data = await response.json();

      if (response.ok) {
        resetForm();
        setSubmitted(true);
        setShowAlert(true);
        setIsOpen(false);
        toast({
          title: "Contact form submitted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status == 500) {
        toast({
          title: "Server Error.",
          description: data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error("Failed to send request:", error);
      setErrors(error.message);
      toast({
        title: "Error.",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const recalculate = async (e) => {
    setMessage(e.target.value);
    setCharCount(e.target.value.length);
  };

  const resetForm = () => {
    setEmail("");
    setReason("");
    setMessage("");
    setSubmitted(false);
    setEmptyFields([]);
    setErrors("");
    setCharCount(0);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setIsButtonVisible(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsButtonVisible(true);
    setShowAlert(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={isLargerThan768 ? "xl" : "sm"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("contact_us")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            bg={useColorModeValue("white", "gray.700")}
            borderRadius="lg"
            p={isLargerThan768 ? 8 : 2}
            color={useColorModeValue("gray.700", "whiteAlpha.900")}
            shadow="base"
          >
            <VStack spacing={5}>
              {!token && (
                <FormControl isRequired>
                  <FormLabel htmlFor="email">{t("email_address")}</FormLabel>
                  <Input
                    placeholder={t("enter_email")}
                    value={signedInEmail || email}
                    onChange={(e) => setEmail(e.target.value)}
                    borderColor={
                      emptyFields.includes("email") ? "red.500" : null
                    }
                    id="email"
                    disabled={signedInEmail}
                  />
                  {errors && (
                    <Text color="red.500" fontSize="sm">
                      {errors}
                    </Text>
                  )}
                  {emptyFields.includes("email") && email.trim() === "" && (
                    <Text color="red.500" fontSize="sm">
                      {t("email_required")}
                    </Text>
                  )}
                </FormControl>
              )}

              <FormControl isRequired>
                <FormLabel htmlFor="reason">{t("how_can_we_help")}</FormLabel>
                <Select
                  value={reason}
                  placeholder={t("choose_reason")}
                  onChange={(e) => setReason(e.target.value)}
                  borderColor={
                    emptyFields.includes("reason") ? "red.500" : null
                  }
                  id="reason"
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                {emptyFields.includes("reason") && reason.trim() === "" && (
                  <Text color="red.500" fontSize="sm">
                    {t("reason_required")}
                  </Text>
                )}
              </FormControl>

              <Grid
                w="full"
                h="full"
                templateAColumns="ifr max-content"
                columnGap={"4"}
              >
                <FormControl isRequired h={"full"}>
                  <FormLabel htmlFor="message">{t("your_message")}</FormLabel>
                  <Textarea
                    value={message}
                    placeholder=""
                    onChange={recalculate}
                    className="resizable-input"
                    id="message"
                    maxLength="1500"
                  />
                  {emptyFields.includes("message") && message.trim() === "" && (
                    <Text color="red.500" fontSize="sm">
                      {t("message_required")}
                    </Text>
                  )}
                </FormControl>

                <Text color="gray.500" fontSize="sm" textAlign="right">
                  {t("characters_count", { charCount: charCount })}
                </Text>
              </Grid>

              <Stack direction="row" spacing={2}>
                <Button
                  fontFamily="heading"
                  onClick={handleSend}
                  disabled={!email || !reason || !message}
                  bg="main.primary"
                  _hover={{ bg: "main.secondary" }}
                  mt={2}
                  color="white"
                  width="100%"
                >
                  {t("send_message")}
                </Button>
              </Stack>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          {submitted && showAlert && (
            <Alert
              status="success"
              variant="subtle"
              style={{ width: "fit-content", margin: "0 auto" }}
            >
              <AlertIcon />
              {t("form_submitted")}
            </Alert>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
