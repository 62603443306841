import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    Button,
    useMediaQuery,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter

} from "@chakra-ui/react";

export default function TutorialPopover({ childComponent, stepNumber, lastStep, skipSteps = 0 }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const { t } = useTranslation();

    useEffect(() => {
        // Check the URL parameter initially
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("tutorial")) {
            setIsOpen(stepNumber === parseInt(urlParams.get("tutorial")));
        }

        // Event listener to update `isOpen` when "tutorialStepChange" is dispatched
        const handleStepChange = (e) => {
            const newStep = e.detail;
            setIsOpen(stepNumber === newStep);
        };

        // Listen for the custom event
        window.addEventListener("tutorialStepChange", handleStepChange);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("tutorialStepChange", handleStepChange);
        };
    }, [stepNumber]);

    const handleBack = () => {
        setIsOpen(false);

        // Update the URL and trigger the custom event
        setTimeout(() => {
            const newStep = stepNumber - 1;
            const url = new URL(window.location);
            url.searchParams.set("tutorial", newStep);
            window.history.pushState({}, '', url);

            // Dispatch custom event for all instances
            window.dispatchEvent(new CustomEvent("tutorialStepChange", { detail: newStep }));
        }, 100);
    };
    const handleNext = () => {
        setIsOpen(false);

        // Update the URL and trigger the custom event
        setTimeout(() => {
            const newStep = stepNumber + 1 + skipSteps;
            const url = new URL(window.location);
            url.searchParams.set("tutorial", newStep);
            window.history.pushState({}, '', url);

            // Dispatch custom event for all instances
            window.dispatchEvent(new CustomEvent("tutorialStepChange", { detail: newStep }));
        }, 100);
    };



    const handleClose = () => {
        setIsOpen(false);

        // Remove the tutorial parameter from the URL and close all instances
        const url = new URL(window.location);
        url.searchParams.delete("tutorial");
        window.history.pushState({}, '', url);

        // Dispatch custom event to close all instances
        window.dispatchEvent(new CustomEvent("tutorialStepChange", { detail: null }));
    };

    return (
        stepNumber === 0 ?
            <Modal isOpen={isOpen} onClose={handleClose} isCentered>
                <ModalOverlay />
                <ModalContent m={3}>
                    <ModalHeader>{t(`tutorial.step${stepNumber}.title`)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t(`tutorial.step${stepNumber}.description`)}
                    </ModalBody>
                    <ModalFooter>

                        <Button colorScheme="blue" onClick={handleNext}>
                            {t('lets_go')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> :
            <Popover
                isOpen={isOpen}
                onClose={handleClose}
                placement="auto"
                closeOnBlur={false}
                closeOnEsc={false}

            >
                <PopoverTrigger>
                    {childComponent}
                </PopoverTrigger>
                <PopoverContent m={3}
                >
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    <PopoverHeader fontWeight="bold">
                        {t(`tutorial.step${stepNumber}.title`)}
                    </PopoverHeader>
                    <PopoverBody>
                        {t(`tutorial.step${stepNumber}.description`)}
                    </PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                        {stepNumber > 1 && <Button colorScheme="gray" mr={2} onClick={handleBack}>
                            {t(`back`)}
                        </Button>}
                        <Button colorScheme="blue" onClick={lastStep ? handleClose : handleNext}>
                            {lastStep ? t('close') : t(`next`)}
                        </Button>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
    );
}
