import { Flex, Text, HStack, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContactFormComponent from "./ContactFormComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// import { Link } from "react-router-dom";

const BottomBar = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <HStack
            width="100%"
            justify="center"
            align="center"
            mt="5"
            alignContent={"center"}
            justifyContent={"center"}
            spacing={5}
            borderTopWidth="1px" borderRadius="lg" 
            pb={3}
            
        >
            <Link
                fontSize="md"
                onClick={() => {
                    setIsOpen(true);
                }}
                flex={1}
                textAlign="right"
            >
                {t("contact_us")}
            </Link>
            <Link fontSize="md" >
                {t("support_us")}
            </Link>
            <Link fontSize="md" flex={1} onClick={() => {
                navigate(`/imprint`);
            }}>
                {t("imprint")}

            </Link>

            <ContactFormComponent isOpen={isOpen} setIsOpen={setIsOpen} />
        </HStack>
    );
}

export default BottomBar;