import React, { useState, useEffect, useContext } from "react";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import VerifyEMailComponent from "../components/VerifyEMailComponent";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useMediaQuery,
  useColorModeValue,
} from "@chakra-ui/react";

import request from "../api";
import AuthContext from "../AuthContext";
import { SearchResultCard } from "../components/SearchResultCard";
import { SkeletonSearchCard } from "../components/SkeletonSearchCard";
import { Pagination } from "../components/Pagination";
import { FilterTab } from "../components/FilterTab";
import { SearchSort } from "../components/SearchSort";
import CreateCourseModal from "../components/CreateCourse";
import { useTranslation } from "react-i18next";

const SearchPage = () => {
  const { isSignedIn, role } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const searchParamsfromState = () => {
    const filters = {
      q: queryParams.q,
      sortBy,
      sortOrder,
      takableCoursesOnly,
      page: currentPage,
      minAverageRating,
      minRatingCount,
      languages,
      examTypes,
      hasBonus,
      examRetakeAtTheEndOfTheSemester,
      examRetakeNextSemester,
      offeredInSemesters,
      moduleLevels,
      areas,
      responsibles,
      attendanceMin,
      attendanceMax,
      previousKnowledgeMin,
      previousKnowledgeMax,
      contentMin,
      contentMax,
      effortPerCreditMin,
      effortPerCreditMax,
      repVsTransMin,
      repVsTransMax,
      mathMin,
      mathMax,
    };

    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(filters)) {
      if (
        value !== undefined &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0) &&
        value !== ""
      ) {
        searchParams.append(key, value);
      }
    }
    return searchParams;
  };

  let queryParams = new URLSearchParams(location.search);
  queryParams = Object.fromEntries(queryParams.entries());

  const [searchResults, setSearchResults] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [paginationResult, setPaginationResult] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [possibleLanguages, setPossibleLanguages] = useState([]);
  const [possibleExamTypes, setPossibleExamTypes] = useState([]);
  const [possibleAreas, setPossibleAreas] = useState([]);
  const [possibleResponsibles, setPossibleResponsibles] = useState([]);
  const [possibleModuleLevels, setPossibleModuleLevels] = useState([]);
  const [possibleOfferedInSemesters, setPossibleOfferedInSemesters] = useState(
    []
  );
  const [query, setQuery] = useState(queryParams.q || "");
  const [sortBy, setSortBy] = useState(queryParams.sortBy || "name");
  const [sortOrder, setSortOrder] = useState(queryParams.sortOrder || "asc");

  const [minAverageRating, setMinAverageRating] = useState(
    queryParams.minAverageRating
  );
  const [minRatingCount, setMinRatingCount] = useState(
    queryParams.minRatingCount || 0
  );

  const [takableCoursesOnly, setTakableCoursesOnly] = useState(
    isSignedIn && allowedRoles.includes(role)
      ? queryParams.takableCoursesOnly ||
          (isSignedIn && allowedRoles.includes(role))
      : true
  );

  const [languages, setLanguages] = useState(
    queryParams.languages ? queryParams.languages.split(",") : []
  );

  const [examTypes, setExamTypes] = useState(
    queryParams.examTypes ? queryParams.examTypes.split(",") : []
  );

  const [hasBonus, setHasBonus] = useState(queryParams.hasBonus);

  const [examRetakeAtTheEndOfTheSemester, setExamRetakeAtTheEndOfTheSemester] =
    useState(
      queryParams.examRetakeAtTheEndOfTheSemester === "true" ? true : false
    );

  const [examRetakeNextSemester, setExamRetakeNextSemester] = useState(
    queryParams.examRetakeNextSemester === "true" ? true : false
  );

  const [offeredInSemesters, setOfferedInSemesters] = useState(
    queryParams.offeredInSemesters
      ? queryParams.offeredInSemesters.split(",")
      : []
  );

  const [moduleLevels, setModuleLevels] = useState(
    queryParams.moduleLevels ? queryParams.moduleLevels.split(",") : []
  );

  const [areas, setAreas] = useState(
    queryParams.areas ? queryParams.areas.split(",") : []
  );

  const [responsibles, setResponsibles] = useState(
    queryParams.responsibles ? queryParams.responsibles.split(",") : []
  );

  const [attendanceMin, setAttendanceMin] = useState(
    queryParams.attendanceMin || 0
  );
  const [attendanceMax, setAttendanceMax] = useState(
    queryParams.attendanceMax || 100
  );
  const [previousKnowledgeMin, setPreviousKnowledgeMin] = useState(
    queryParams.previousKnowledgeMin || 0
  );
  const [previousKnowledgeMax, setPreviousKnowledgeMax] = useState(
    queryParams.previousKnowledgeMax || 100
  );
  const [contentMin, setContentMin] = useState(queryParams.contentMin || 0);
  const [contentMax, setContentMax] = useState(queryParams.contentMax || 100);
  const [effortPerCreditMin, setEffortPerCreditMin] = useState(
    queryParams.effortPerCreditMin || 0
  );
  const [effortPerCreditMax, setEffortPerCreditMax] = useState(
    queryParams.effortPerCreditMax || 100
  );
  const [repVsTransMin, setRepVsTransMin] = useState(
    queryParams.repVsTransMin || 0
  );
  const [repVsTransMax, setRepVsTransMax] = useState(
    queryParams.repVsTransMax || 100
  );
  const [mathMin, setMathMin] = useState(queryParams.mathMin || 0);
  const [mathMax, setMathMax] = useState(queryParams.mathMax || 100);

  const [currentPage, setCurrentPage] = useState(queryParams.page || 1);
  const [totalPages, setTotalPages] = useState(10);

  const [ratingHover, setRatingHover] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);
  const [ratingSelected, setRatingSelected] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [isCreateCourseOpen, setIsCreateCourseOpen] = useState(false);
  const handleOpenModal = () => {
    setIsCreateCourseOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateCourseOpen(false);
  };

  const fetchSearchAndFilterResults = async () => {
    try {
      setIsLoading(true);
      setQuery(queryParams.q);
      const searchParams = searchParamsfromState();

      const url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/home/details-search?${searchParams.toString()}&limit=10`;
      const data = await request(url, "GET");

      setSearchResults(data.courses);

      setPaginationResult(data.paginationResult);
      setTotalPages(data.paginationResult.totalPages);

      if (
        data.paginationResult.totalPages < data.paginationResult.currentPage
      ) {
        setCurrentPage(data.paginationResult.totalPages);
      } else if (
        data.paginationResult.currentPage < 1 &&
        data.paginationResult.totalPages > 0
      ) {
        setCurrentPage(1);
      } else {
        setCurrentPage(data.paginationResult.currentPage);
      }

      setPossibleLanguages(data.possibleFilterOptions.languages);
      setPossibleExamTypes(data.possibleFilterOptions.examTypes);
      setPossibleAreas(data.possibleFilterOptions.areas);
      setPossibleResponsibles(data.possibleFilterOptions.responsibles);
      setPossibleModuleLevels(data.possibleFilterOptions.moduleLevels);
      setPossibleOfferedInSemesters(
        data.possibleFilterOptions.offeredInSemesters
      );

      for (const [key, value] of Object.entries(data.appliedFilters)) {
        if (Array.isArray(value)) {
          for (const v of value) {
            searchParams.append(key, v);
          }
        } else {
          searchParams.append(key, value);
        }
      }
      setIsLoading(false);
      useNavigate(`?${searchParams.toString()}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSearchAndFilterResults();
  }, [sortBy, sortOrder, filtersApplied, currentPage, query, queryParams.q]);

  const applyFilters = () => {
    const searchParams = searchParamsfromState();
    setFiltersApplied(!filtersApplied);
    navigate(`/courses?${searchParams.toString()}`);
  };

  const resetFilters = () => {
    setFiltersApplied(!filtersApplied);
    if (query !== "") {
      navigate(`/courses?q=${query}&sortBy=averageRating&sortOrder=desc`);
    } else {
      navigate(`/courses?sortBy=averageRating&sortOrder=desc`);
    }
    // setQuery('');
    setSortBy("name");
    setSortOrder("asc");
    setMinAverageRating(undefined);
    setMinRatingCount(0);
    setLanguages([]);
    setExamTypes([]);
    setHasBonus(undefined);
    setExamRetakeAtTheEndOfTheSemester(undefined);
    setExamRetakeNextSemester(undefined);
    setOfferedInSemesters([]);
    setModuleLevels([]);
    setAreas([]);
    setResponsibles([]);
    setAttendanceMin(0);
    setAttendanceMax(100);
    setPreviousKnowledgeMin(0);
    setPreviousKnowledgeMax(100);
    setContentMin(0);
    setContentMax(100);
    setEffortPerCreditMin(0);
    setEffortPerCreditMax(100);
    setRepVsTransMin(0);
    setRepVsTransMax(100);
    setMathMin(0);
    setMathMax(100);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortOrder", newSortOrder);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleSortChange = (event) => {
    setSortBy(event);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortBy", event);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", newPage);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRatingHoverEnter = (value) => {
    setRatingHover(value);
    setMouseIn(true);
  };

  const handleRatingHoverLeave = (value) => {
    setMouseIn(false);
    setRatingHover(value);
  };

  const handleRatingClick = (value) => {
    setRatingSelected(true);
    setMinAverageRating(value);
  };

  return (
    <>
      <Navbar loggedIn={isSignedIn && allowedRoles.includes(role)} />
      <Box pos={"relative"} top={79}>
        {isSignedIn && allowedRoles.includes(role) && <VerifyEMailComponent />}

        <Flex pl={2}>
          {isLargerThan768 && (
          <FilterTab
          isSignedIn={isSignedIn && allowedRoles.includes(role)}
          takableCoursesOnly={takableCoursesOnly}
          setTakableCoursesOnly={setTakableCoursesOnly}
          minAverageRating={minAverageRating}
          ratingSelected={ratingSelected}
          mouseIn={mouseIn}
          ratingHover={ratingHover}
          handleRatingHoverEnter={handleRatingHoverEnter}
          handleRatingHoverLeave={handleRatingHoverLeave}
          handleRatingClick={handleRatingClick}
          minRatingCount={minRatingCount}
          setMinRatingCount={setMinRatingCount}
          possibleLanguages={possibleLanguages}
          languages={languages}
          setLanguages={setLanguages}
          possibleExamTypes={possibleExamTypes}
          examTypes={examTypes}
          setExamTypes={setExamTypes}
          possibleAreas={possibleAreas}
          areas={areas}
          setAreas={setAreas}
          possibleResponsibles={possibleResponsibles}
          responsibles={responsibles}
          setResponsibles={setResponsibles}
          possibleOfferedInSemesters={possibleOfferedInSemesters}
          offeredInSemesters={offeredInSemesters}
          setOfferedInSemesters={setOfferedInSemesters}
          possibleModuleLevels={possibleModuleLevels}
          moduleLevels={moduleLevels}
          setModuleLevels={setModuleLevels}
          examRetakeAtTheEndOfTheSemester={examRetakeAtTheEndOfTheSemester}
          setExamRetakeAtTheEndOfTheSemester={
            setExamRetakeAtTheEndOfTheSemester
          }
          examRetakeNextSemester={examRetakeNextSemester}
          setExamRetakeNextSemester={setExamRetakeNextSemester}
          attendanceMin={attendanceMin}
          attendanceMax={attendanceMax}
          setAttendanceMin={setAttendanceMin}
          setAttendanceMax={setAttendanceMax}
          previousKnowledgeMin={previousKnowledgeMin}
          previousKnowledgeMax={previousKnowledgeMax}
          setPreviousKnowledgeMin={setPreviousKnowledgeMin}
          setPreviousKnowledgeMax={setPreviousKnowledgeMax}
          contentMin={contentMin}
          contentMax={contentMax}
          setContentMin={setContentMin}
          setContentMax={setContentMax}
          effortPerCreditMin={effortPerCreditMin}
          effortPerCreditMax={effortPerCreditMax}
          setEffortPerCreditMin={setEffortPerCreditMin}
          setEffortPerCreditMax={setEffortPerCreditMax}
          repVsTransMin={repVsTransMin}
          repVsTransMax={repVsTransMax}
          setRepVsTransMin={setRepVsTransMin}
          setRepVsTransMax={setRepVsTransMax}
          mathMin={mathMin}
          mathMax={mathMax}
          setMathMin={setMathMin}
          setMathMax={setMathMax}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />)}

          <Box
            p={4}
            pt={0}
            pl={2}
            pos={isLargerThan768 ? "relative" : null}
            left={isLargerThan768 ? "27%" : null}
            w={isLargerThan768? "73%": null}
          >
            <Flex>
              <Flex flex={1} direction={"column"}>
                <HStack>
                  <Spacer />
                  <SearchSort
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    onSortOrderChange={handleSortOrderChange}
                  />
                </HStack>
                {role === "ADMIN" ? (
                  <Button
                    mt={2}
                    mb={4}
                    colorScheme="green"
                    onClick={handleOpenModal}
                  >
                    {t("create_new_course")}
                  </Button>
                ) : (
                  ""
                )}
                <Modal
                  isOpen={isCreateCourseOpen}
                  onClose={handleCloseModal}
                  size="xl"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Create a new course</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <CreateCourseModal onClose={handleCloseModal} />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="white"
                  bg="main.primary"
                  _hover={{ bg: 'main.secondary' }}
                        mr={3}
                        onClick={handleCloseModal}
                      >
                        {t("close")}
                      </Button>
                      <Button variant="ghost" onClick={handleCloseModal}>
                        {t("cancel")}
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                {isLoading
                  ? Array.from({ length: 10 }, (_, i) => (
                      <SkeletonSearchCard key={i} />
                    ))
                  : searchResults.map((course) => (
                      <SearchResultCard course={course} />
                    ))}
              </Flex>
            </Flex>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default SearchPage;
