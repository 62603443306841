import React from "react";
import {
  Box,
  Flex,
  Text,
  Progress,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
} from "@chakra-ui/react";
import RateDetailSlider from "./RateDetailSlider";
import { useTranslation } from "react-i18next";

export default function RateDetail({
  attendance,
  setAttendance,
  previousKnowledge,
  setPreviousKnowledge,
  content,
  setContent,
  effortPerCredit,
  setEffortPerCredit,
  reproductionVsTransfer,
  setReproductionVsTransfer,
  mathIntensity,
  setMathIntensity,
}) {
  const { t } = useTranslation();
  return (
    <Box>
      <Text fontSize={"xl"} textAlign={"center"} mb={3}>
        {t("how_would_you_rate")}{" "}
      </Text>
      <Flex>
        <Box
          mr={1}
          flex={1}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius="md"
          p={2}
        >
          <Text fontWeight={"bold"} fontSize={"lg"}>
            {t("lecture")}
          </Text>
          <RateDetailSlider
            title={t("attendance")}
            lowerScale={t("not_necessary")}
            upperScale={t("necessary")}
            value={attendance}
            setValue={setAttendance}
          />
          <RateDetailSlider
            title={t("previous_knowledge")}
            lowerScale={t("not_necessary")}
            upperScale={t("necessary")}
            value={previousKnowledge}
            setValue={setPreviousKnowledge}
          />
          <RateDetailSlider
            title={t("content")}
            lowerScale={t("boring")}
            upperScale={t("interesting")}
            value={content}
            setValue={setContent}
          />
        </Box>
        <Box
          ml={1}
          flex={1}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius="md"
          p={2}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"right"}>
            {t("exam")}
          </Text>
          <RateDetailSlider
            title={t("effort_per_credit")}
            lowerScale={t("effort_greater_credit")}
            upperScale={t("effort_less_credit")}
            value={effortPerCredit}
            setValue={setEffortPerCredit}
          />
          <RateDetailSlider
            title={t("reproduction_vs_transfer")}
            lowerScale={t("reproduction")}
            upperScale={t("transfer")}
            value={reproductionVsTransfer}
            setValue={setReproductionVsTransfer}
          />
          <RateDetailSlider
            title={t("math_intensity")}
            lowerScale={t("not_intense")}
            upperScale={t("intense")}
            value={mathIntensity}
            setValue={setMathIntensity}
          />
        </Box>
      </Flex>
    </Box>
  );
}
