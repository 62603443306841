import {Box, Stat, StatLabel, StatNumber, Text, useColorModeValue} from "@chakra-ui/react";

export default function StatCard({ label, value }) {
    return (
        <Box
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")  } 
            boxShadow="md"
            borderRadius="md"
            p={4}
            width="200px"
            textAlign="center"
            transition="all 0.3s"
            _hover={{
                transform: 'scale(1.05)',
                boxShadow: 'lg',
            }}
        >
            <Text color="gray.500" fontSize="sm" mb={2} textTransform="uppercase">
                {label}
            </Text>
            <Stat>
                <StatNumber fontSize="4xl" fontWeight="bold">
                    {value}
                </StatNumber>
            </Stat>
        </Box>
    );
}