import { useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Stack,
  Spacer, Code,
  Icon,
  Tag, Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";

export const CourseResult = ({
  course, isSelected, isFromOtherProgram, searchQuery, setSearchQuery, setShowSearchResults,
}) => {
  const resultRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSelected) {
      resultRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isSelected]);


  return (
    <Box
      ref={resultRef}
      color={isFromOtherProgram ? "gray.500" : "gray.900"}
      mt={2}
      bg={isSelected ? "main.secondary" : undefined}
      _hover={isFromOtherProgram ? { bg: "main.secondary" } : { bg: "main.secondary" }}
      borderRadius="md"
      p={2}
      display="flex"
      alignItems="center"
      onClick={() => {
        if (course.courseID === "search-for") {
          navigate(
            `/courses?q=${searchQuery}&sortBy=averageRating&sortOrder=desc`
          );
        } else {
          navigate(`/course/${course._id}`);
          setSearchQuery("");
        }
        setShowSearchResults(false);
      }}
      cursor="pointer"
    >
      <Box ml={4}>
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Stack direction="row" alignItems="center">
            {course.courseID === "search-for" ? (
              <>
                <Icon as={SearchIcon} w={4} h={4} />
                <Text fontSize="md">
                  Search for{" "}
                  <Text as="span" fontWeight="bold">
                    {course.name.en}
                  </Text>
                </Text>
              </>
            ) : (
              <Text fontSize="md" fontWeight="bold">
                {course.name.en}
              </Text>
            )}
            <Spacer />
          </Stack>

          {course?.responsibles && course.responsibles.length > 0 && (
            <Text>
              {course.responsibles.map((responsible) => (
                <Text key={responsible.id} fontSize="sm" fontStyle="italic">
                  {responsible.displayName}
                </Text>
              ))}
            </Text>
          )}
          {course?.department && (
            <Text fontSize="sm" fontStyle="italic">
              {course.department.name.en}
            </Text>
          )}
          {course?.tags && (
            <Flex direction="row" flexWrap="wrap" mt={2}>
              {course.tags &&
                course.tags.map((tag) => (
                  <Tag
                    key={tag}
                    size="sm"
                    mr={2}
                    mb={2}
                    colorScheme="red"
                    borderRadius="full"
                  >
                    {tag
                      .replace("SS", "SUMMER")
                      .replace("WS", "WINTER")
                      .replace(/_/g, " ")}
                  </Tag>
                ))}
            </Flex>
          )}
        </Flex>
      </Box>
      <Spacer />
      {course.courseID !== "search-for" && (
        <Code colorScheme="blue" fontSize="sm" fontWeight="bold">
          {course.courseID}
        </Code>
      )}
    </Box>
  );
};
