import React, { useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MultiSelect } from "chakra-multiselect";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Button,
  Collapse,
  Flex,
  FormControl,
  Text,
  HStack,
  Checkbox,
  FormLabel,
  Slider,
  Icon,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spacer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BsSliders, BsListTask } from "react-icons/bs";

// ===========================================================
export const FilterTab = ({
  isSignedIn,
  takableCoursesOnly,
  setTakableCoursesOnly,
  minAverageRating,
  ratingSelected,
  mouseIn,
  ratingHover,
  handleRatingHoverEnter,
  handleRatingHoverLeave,
  handleRatingClick,
  minRatingCount,
  setMinRatingCount,
  possibleLanguages,
  languages,
  setLanguages,
  possibleExamTypes,
  examTypes,
  setExamTypes,
  possibleAreas,
  areas,
  setAreas,
  possibleResponsibles,
  responsibles,
  setResponsibles,
  possibleOfferedInSemesters,
  offeredInSemesters,
  setOfferedInSemesters,
  possibleModuleLevels,
  moduleLevels,
  setModuleLevels,
  examRetakeAtTheEndOfTheSemester,
  setExamRetakeAtTheEndOfTheSemester,
  examRetakeNextSemester,
  setExamRetakeNextSemester,
  attendanceMin,
  attendanceMax,
  setAttendanceMin,
  setAttendanceMax,
  previousKnowledgeMin,
  previousKnowledgeMax,
  setPreviousKnowledgeMin,
  setPreviousKnowledgeMax,
  contentMin,
  contentMax,
  setContentMin,
  setContentMax,
  effortPerCreditMin,
  effortPerCreditMax,
  setEffortPerCreditMin,
  setEffortPerCreditMax,
  repVsTransMin,
  repVsTransMax,
  setRepVsTransMin,
  setRepVsTransMax,
  mathMin,
  mathMax,
  setMathMin,
  setMathMax,
  applyFilters,
  resetFilters,
}) => {
  const [showCriteriaRatings, setShowCriteriaRatings] = useState(false);
  const [isExpandedDetails, setIsExpandedDetails] = useState(false);
  const [isExpandedStats, setIsExpandedStats] = useState(false);
  const { t } = useTranslation();
  const handleExpandedDetails = () => {
    setIsExpandedDetails(!isExpandedDetails);
  };
  const handleExpandedStats = () => {
    setIsExpandedStats(!isExpandedStats);
  };

  return (
    <Box
      flex={1}
      mr={4}
      pr={4}
      w="27%"
      h={"85vh"}
      shadow={"base"}
      pos="fixed"
      top={87}
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      pl={2}
      mb={4}
      borderRadius={"lg"}
    >
      <Heading mb={4} mt={4} size="lg">
        {t("filters.title")}
      </Heading>
      <Box overflowY="auto" h={"67vh"} pr={2}>
        <HStack my={1}>
          <Spacer />
        </HStack>
        {isSignedIn && (
          <FormControl>
            <HStack my={1}>
              <FormLabel fontSize={"sm"} m={0} mb={4}>
                {t("filters.only_courses_of_my_program")}
              </FormLabel>
              <Spacer />
              <Checkbox
                outline={"1px solid"}
                isChecked={takableCoursesOnly}
                onChange={(e) => setTakableCoursesOnly(e.target.checked)}
              />
            </HStack>
          </FormControl>
        )}

        <FormControl mb={2}>
          <HStack my={1}>
            <FormLabel fontSize={"sm"}>{t("filters.minimum_rating")}</FormLabel>
            <Spacer />
            <Flex alignItems="center">
              {[1, 2, 3, 4, 5].map((value) => (
                <Icon
                  key={value}
                  as={
                    (ratingSelected && !mouseIn
                      ? minAverageRating
                      : ratingHover) >= value
                      ? AiFillStar
                      : AiOutlineStar
                  }
                  boxSize={6}
                  color={
                    (ratingSelected && !mouseIn
                      ? minAverageRating
                      : ratingHover) >= value
                      ? "yellow.400"
                      : "gray.300"
                  }
                  onMouseEnter={() => handleRatingHoverEnter(value)}
                  onMouseLeave={() =>
                    ratingSelected
                      ? handleRatingHoverLeave(minAverageRating)
                      : handleRatingHoverLeave(0)
                  }
                  onClick={() => handleRatingClick(value)}
                  cursor="pointer"
                />
              ))}
            </Flex>
          </HStack>
        </FormControl>
        <FormControl mb={2}>
          <HStack my={1} spacing={0}>
            <FormLabel fontSize={"sm"} m={0} mr={2}>
              {t("filters.minimum_rating_count")}
            </FormLabel>
            <Spacer />
            <NumberInput
              value={minRatingCount}
              onChange={(value) => setMinRatingCount(parseInt(value))}
              min={0}
              max={100}
              step={1}
            >
              <NumberInputField
                size="sm"
                style={{
                  height: "28px",
                  width: "100px",
                  padding: "0.25rem",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        </FormControl>
        <HStack>
          <Button
            p={0}
            onClick={handleExpandedDetails}
            variant="ghost"
            size="md"
            leftIcon={
              isExpandedDetails ? (
                <ChevronDownIcon boxSize={5} />
              ) : (
                <ChevronRightIcon boxSize={5} />
              )
            }
            _hover={""}
          >
            {t("filters.filter_by_details")}
          </Button>
          <Spacer />
          <Icon as={BsListTask} ml={1} />
        </HStack>

        <Collapse in={isExpandedDetails} animateOpacity>
          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.language")}
            </FormLabel>
            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_languages")}
              options={possibleLanguages.map((label) => ({
                label,
                value: label,
              }))}
              value={languages}
              onChange={setLanguages}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.exam_type")}
            </FormLabel>
            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_exam_types")}
              options={possibleExamTypes.map((label) => ({
                label,
                value: label,
              }))}
              value={examTypes}
              onChange={setExamTypes}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.areas")}
            </FormLabel>

            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_course_areas")}
              options={possibleAreas.map((area) => ({
                value: area.name.en,
                label: area._id,
              }))}
              value={areas}
              onChange={setAreas}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.responsibles")}
            </FormLabel>

            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_responsibles")}
              options={possibleResponsibles.map((resp) => ({
                label: resp.displayName,
                value: resp.displayName,
              }))}
              value={responsibles}
              onChange={setResponsibles}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.semester")}
            </FormLabel>
            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_semesters")}
              options={possibleOfferedInSemesters.map((label) => ({
                label,
                value: label,
              }))}
              value={offeredInSemesters}
              onChange={setOfferedInSemesters}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"} m={0} mt={1} mb={2}>
              {t("filters.module_level")}
            </FormLabel>
            <MultiSelect
              size={"sm"}
              placeholder={t("filters.select_module_levels")}
              options={possibleModuleLevels.map((label) => ({
                label,
                value: label,
              }))}
              value={moduleLevels}
              onChange={setModuleLevels}
            />
          </FormControl>
          {/* if examAtTheENd of semester etc. add button */}

          <HStack spacing={0} py={1}>
            <FormLabel fontSize={"sm"} m={0} mr={3}>
              {t("filters.retake")}
            </FormLabel>

            <Checkbox
              mr={1}
              mt={1}
              outline={"1px solid"}
              isChecked={examRetakeAtTheEndOfTheSemester}
              onChange={(e) =>
                setExamRetakeAtTheEndOfTheSemester(e.target.checked)
              }
            />
            <Text fontSize={"sm"} mr={3}>
              {t("filters.end_of_semester")}
            </Text>

            <Checkbox
              mr={1}
              mt={1}
              isChecked={examRetakeNextSemester}
              onChange={(e) => setExamRetakeNextSemester(e.target.checked)}
              outline={"1px solid"}
            />
            <Text fontSize={"sm"}>{t("filters.next_semester")}</Text>
          </HStack>
        </Collapse>

        <HStack>
          <Button
            p={0}
            onClick={handleExpandedStats}
            variant="ghost"
            size="md"
            leftIcon={
              isExpandedStats ? (
                <ChevronDownIcon boxSize={5} />
              ) : (
                <ChevronRightIcon boxSize={5} />
              )
            }
            _hover={""}
          >
            {t("filters.filter_by_stats")}
          </Button>
          <Spacer />
          <Icon as={BsSliders} ml={1} />
        </HStack>

        <Collapse in={isExpandedStats} animateOpacity>
          <FormControl mb={2} mt={4}>
            <FormLabel fontSize={"sm"}> {t("attendance")}</FormLabel>
            <Slider
              aria-label="attendance-slider"
              defaultValue={[attendanceMin, attendanceMax]}
              onChange={(value) => {
                setAttendanceMin(value[0]);
                setAttendanceMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("not_necessary")}</Text>
              <Text fontSize={"xs"}>{t("necessary")}</Text>
            </Flex>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize={"sm"}>{t("previous_knowledge")}</FormLabel>
            <Slider
              aria-label="previous-knowledge-slider"
              defaultValue={[previousKnowledgeMin, previousKnowledgeMax]}
              onChange={(value) => {
                setPreviousKnowledgeMin(value[0]);
                setPreviousKnowledgeMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("not_necessary")}</Text>
              <Text fontSize={"xs"}>{t("attennecessarydance")}</Text>
            </Flex>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize="sm">{t("content")}</FormLabel>
            <Slider
              aria-label="content-slider"
              defaultValue={[contentMin, contentMax]}
              onChange={(value) => {
                setContentMin(value[0]);
                setContentMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("boring")}</Text>
              <Text fontSize={"xs"}>{t("interesting")}</Text>
            </Flex>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize="sm">{t("effort_per_credit")}</FormLabel>
            <Slider
              aria-label="effort-per-credit-slider"
              defaultValue={[effortPerCreditMin, effortPerCreditMax]}
              onChange={(value) => {
                setEffortPerCreditMin(value[0]);
                setEffortPerCreditMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("effort_greater_credit")}</Text>
              <Text fontSize={"xs"}>{t("effort_less_credit")}</Text>
            </Flex>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize="sm">{t("reproduction_vs_transfer")}</FormLabel>
            <Slider
              aria-label="reproduction-transfer-slider"
              defaultValue={[repVsTransMin, repVsTransMax]}
              onChange={(value) => {
                setRepVsTransMin(value[0]);
                setRepVsTransMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("reproduction")}</Text>
              <Text fontSize={"xs"}>{t("transfer")}</Text>
            </Flex>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel fontSize="sm">{t("math_intensity")}</FormLabel>
            <Slider
              aria-label="math-slider"
              defaultValue={[mathMin, mathMax]}
              onChange={(value) => {
                setMathMin(value[0]);
                setMathMax(value[1]);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Flex justify="space-between">
              <Text fontSize={"xs"}>{t("not_intense")}</Text>
              <Text fontSize={"xs"}>{t("intense")}</Text>
            </Flex>
          </FormControl>
        </Collapse>
      </Box>
      <HStack  bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")} >
       
        <Button onClick={resetFilters} colorScheme="gray" size="md">
          {t("filters.reset_filters")}
        </Button>
        <Button
          onClick={applyFilters}
          bg="main.primary"
          color="white"
          size="md"
        >
          {t("filters.apply_filters")}
        </Button>
      </HStack>
    </Box>
  );
};
