import React from "react";
import { SimpleGrid, Text, Link } from "@chakra-ui/react";
import CourseDetailRow from "./CourseDetailRow";
import { useTranslation } from "react-i18next";

export default function CourseDetails({ courseID, courseData }) {
  const { t } = useTranslation();
  return (
    <SimpleGrid
      templateColumns="auto auto"
      spacing={2}
      ml={2}
      pb={2}
      maxWidth={450}
    >
      <CourseDetailRow
        key={"courseID"}
        detail={t("course_code")}
        value={courseData?.courseCode || courseID}
      />
      <CourseDetailRow
        key={"area"}
        detail={t("area")}
        value={
          courseData?.area && (
            <Link href={"/courses?areas=" + courseData?.area.replace(" ", "+")}>
              {" "}
              <Text fontWeight={"bold"}>{courseData?.area}</Text>
            </Link>
          )
        }
      />
      <CourseDetailRow
        key={"hasBonus"}
        detail={t("bonus")}
        value={courseData?.hasBonus ? t("yes") : t("no")}
      />
      {courseData?.offeredInSemesters != null ? (
        <CourseDetailRow
          key={"semester"}
          detail={t("semester")}
          value={courseData?.offeredInSemesters}
        />
      ) : null}
      <CourseDetailRow
        key={"credits"}
        detail={t("credits")}
        value={courseData?.credits + " ects"}
      />
      <CourseDetailRow
        key={"level"}
        detail={t("level")}
        value={courseData?.moduleLevel}
      />
      <CourseDetailRow
        key={"languages"}
        detail={t("language")}
        value={courseData?.languages}
      />
      <CourseDetailRow
        key={"prerequisites"}
        detail={t("prerequisites")}
        value={courseData?.prerequisiteCourses.map((prerequisite) => (
          <Link href={"/course/" + prerequisite._id}>
            {" "}
            <Text fontWeight={"bold"}>{prerequisite.courseCode}</Text>
          </Link>
        ))}
        link={"/course/"}
        dontChangeCase={true}
      />
      <CourseDetailRow
        key={"responsibles"}
        detail={t("responsible")}
        value={courseData?.responsibles?.map((responsible) => (
          <Link
            href={
              "/courses?responsibles=" +
              responsible.displayName?.replace(" ", "+")
            }
          >
            {" "}
            <Text fontWeight={"bold"}>{responsible.displayName}</Text>
          </Link>
        ))}
        dontChangeCase={true}
      />
      <CourseDetailRow
        key={"department"}
        detail={t("department")}
        value={courseData?.department}
      />
      <CourseDetailRow
        key={"courseWebsite"}
        detail={t("course_website")}
        value={
          <Link href={courseData?.website} isExternal>
            <Text fontWeight={"bold"}>
              {courseData?.website
                ? new URL(courseData?.website).hostname
                : t("loading")}
            </Text>
          </Link>
        }
      />
    </SimpleGrid>
  );
}
