import { useState, useEffect, useContext } from "react";

import {
  Stack,
  Heading,
  Text,
  Box,
  Input,
  Button,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  Link,
  FormControl,
  FormLabel,
  useMediaQuery,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,

} from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons'
import request from "../api";
import AuthContext from "../AuthContext";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SignUpComponent() {
  const toast = useToast();

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [username, setUsername] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [invitedBy, setInvitedBy] = useState("");
  const [studyProgram, setStudyProgram] = useState("");
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [university, setUniversity] = useState("");
  const [universities, setUniversities] = useState([]);
  const [studyProgramType, setStudyProgramType] = useState("BACHELOR");
  const [studyPrograms, setStudyPrograms] = useState([]);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errors, setErrors] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [inviteCodeError, setInviteCodeError] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);
  const [usernameError, setUsernameError] = useState("");
  const [usernameEmailError, setUsernameEmailError] = useState("");
  const [mailPatternError, setMailPatternError] = useState("");
  const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
  const { handleSignIn, isSignedIn, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const { t, i18n } = useTranslation();

  function createTextWithLinks(text, dictionary) {
    const words = text.split("|");
    const elements = words.map((word, index) => {
      if (dictionary[word]) {
        return (
          <Link key={index} fontWeight="bold" href={dictionary[word]}>
            {word}
          </Link>
        );
      } else {
        return word + " ";
      }
    });

    return <Text>{elements}</Text>;
  }
  if (isSignedIn && allowedRoles.includes(role)) {
    window.location.href = "/";
  }
  useEffect(() => {
    const inviteCodeParam = urlParams.get("inviteCode");
    if (inviteCodeParam) {
      setInviteCode(inviteCodeParam);

      getUsername(inviteCodeParam);
    }

  }, []);
  useEffect(() => {
    setUsernameEmailError("");
    setUsernameError("");
  }, [username, email]);

  useEffect(() => {
    setUsernameEmailError("");
    setMailPatternError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  const fetchInstitutions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/institutions`);
      const data = await response.json();

      const institutions = data.institutions;
      const defaultInstitution = institutions[0];
      setUniversities(institutions);
      setUniversity(defaultInstitution);
    } catch (error) {
      console.error("Failed to fetch institutions:", error);
    }
  };

  const getUsername = async (inviteCode) => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/invite-code?inviteCode=${inviteCode}`,
        "GET"
      );
      if (response.invitedBy) {
        setInvitedBy(response.invitedBy);
      } else if (response.error === "invite_code_invalid") {
        setInviteCodeError(t("signup_error.invite_code_invalid"));
      } else if (response.error === "invite_code_already_used") {
        setInviteCodeError(t("signup_error.invite_code_used"));
      } else if (response.error === "invite_code_expired") {
        setInviteCodeError(t("signup_error.invite_code_expired"));
      }

    } catch (error) {
      console.error("Failed to fetch invite code:", error);
    }
  };

  const resetStudyProgram = () => {
    setStudyProgram(null);
  };

  useEffect(() => {
    fetchInstitutions();
  }, []);

  useEffect(() => {
    fetchSchools();
  }, [university]);

  useEffect(() => {
    fetchStudyPrograms();
  }, [studyProgramType, school, university]);

  const fetchStudyPrograms = async () => {
    if (university && studyProgramType && school) {
      try {
        const data = await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/degree-programs/?programType=${studyProgramType}&universityID=${university.id}&schoolID=${school}`,
          "GET"
        );
        setStudyPrograms(data.allDegreePrograms);
      } catch (error) {
        console.error("Failed to fetch study programs:", error);
      }
    }
  };

  const fetchSchools = async () => {
    if (university) {
      try {
        const data = await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/schools/?universityID=${university.id}`,
          "GET"
        );
        setSchools(data.allSchools);
      } catch (error) {
        console.error("Failed to fetch schools:", error);
      }
    }
  };
  const requiredFields = [
    { field: "username", value: username },
    { field: "email", value: email },
    { field: "password", value: password },
    { field: "university", value: university },
    { field: "studyProgram", value: studyProgram },
    { field: "school", value: school },
    { field: "agreeTerms", value: agreeTerms },
    { field: "inviteCode", value: inviteCode }
  ];
  async function handleSignUp() {

    let errorsFound = false;
    const emptyFields = requiredFields
      .filter(
        (field) =>
          !field.value ||
          (Array.isArray(field.value) && field.value.trim.length === 0)
      )
      .map((field) => field.field);

    if (!studyProgram) {
      emptyFields.push("studyProgram");
    }

    if (!agreeTerms) {
      emptyFields.push("agreeTerms");
    }

    setEmptyFields(emptyFields);

    if (emptyFields.length > 0) {
      errorsFound = true;
    }

    if (username.length < 3) {
      setUsernameError(t("signup_error.username_too_short"));
      errorsFound = true;
    }
    else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      setUsernameError(t("signup_error.username_invalid"));
      errorsFound = true;
    } else {
      setUsernameError("");
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      setMailPatternError(t("signup_error.email_invalid"));
      errorsFound = true;
    }

    if (inviteCode.length !== 16) {
      console.log("error invite code");
      console.log(inviteCode.length);
      setInviteCodeError(t("signup_error.invite_code_invalid"));
      errorsFound = true;
    } else {
      setInviteCodeError("");
    }

    if (password.length < 8) {
      setPasswordError(t("signup_error.password_too_short"));
      errorsFound = true;
    } else {
      setPasswordError("");
    }

    if (!errorsFound) {
      try {
        const response = await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/register`,
          "POST",
          {
            username,
            email,
            password,
            institutionID: university.id,
            degreeProgramID: studyProgram,
            schoolID: school,
            inviteCode,
          }
        );

        if (response.error === "university_email_pattern_error") {
          setMailPatternError(i18n.language == "de" ? response.message.de : response.message.en);

        } else if (response.error === "user_exists") {
          setUsernameEmailError(t("signup_error.username_email_exists"));
        } else if (response.error === "invite_code_invalid") {
          setInviteCodeError(t("signup_error.invite_code_invalid"));
        } else {
          const { access_token, expires_in, role } = response;
          handleSignIn(access_token, expires_in, role);

          navigate("/?tutorial=0");

        }
      } catch (error) {

        setErrors(error.message);

      }
    }
  }

  return (
    <Box >
      <Box h={8} />

      <Stack

        bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
        rounded="xl"
        p={4}
        spacing={4}
        direction="column"
        shadow="lg"
      >
        <Heading lineHeight={1.1} fontSize="4xl">
          {t("sign_up")}
        </Heading>
        <Text fontSize="md">{t("create_account_description")}</Text>

        <Box as="form">
          <Stack spacing={2}>
            <FormControl isInvalid={emptyFields.includes("username")} isRequired>
              <FormLabel htmlFor="username">{t("username")}</FormLabel>
              <Input
                placeholder={t("your_username")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                borderColor="gray.800"
                id="username"
                autoComplete="username"
              />
              {usernameError && (
                <Text color="red.500" fontSize="sm">
                  {usernameError}
                </Text>
              )}

              {!usernameError && usernameEmailError && (
                <Text color="red.500" fontSize="sm">
                  {usernameEmailError}
                </Text>
              )}
            </FormControl>

            <FormControl isInvalid={emptyFields.includes("email")} isRequired>
              <FormLabel htmlFor="email">{t("university_email")}
                <Popover>
                  <PopoverTrigger>
                    <InfoIcon color="gray.500" ml={1} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>{t("why_university_email")}</PopoverHeader>
                    <PopoverBody>
                      <Text fontWeight={"light"}>
                        {t("university_email_info")}
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormLabel>
              <Input
                placeholder={t("your_email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                borderColor="gray.800"
                id="email"
                autoComplete="email"
              />
              {!usernameError && usernameEmailError && (
                <Text color="red.500" fontSize="sm">
                  {usernameEmailError}
                </Text>
              )}
              {mailPatternError && (
                <Text color="red.500" fontSize="sm">
                  {mailPatternError}
                </Text>
              )}
            </FormControl>
            <FormControl isInvalid={emptyFields.includes("password")} isRequired>
              <FormLabel htmlFor="password">{t("password")}</FormLabel>
              <Input
                type="password"
                placeholder={t("your_password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                borderColor="gray.800"
                id="password"
                autoComplete="new-password"
              />
              {passwordError && (
                <Text color="red.500" fontSize="sm">
                  {passwordError}
                </Text>
              )}
            </FormControl>

            <FormControl
              isInvalid={emptyFields.includes("university")}
              isRequired
            >
              <FormLabel htmlFor="university">{t("university")}</FormLabel>
              <Select
                value={university}
                placeholder={t("select_university")}
                onChange={(e) => setUniversity(e.target.value)}
                borderColor="gray.800"
                id="university"
                disabled={universities.length <= 1}
              >
                {universities.map((university) => (
                  <option key={university.id} value={university}>
                    {university.name.en}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isInvalid={emptyFields.includes("school")} isRequired>
              <FormLabel htmlFor="school">{t("school")}</FormLabel>
              <Select
                value={school}
                placeholder={t("select_school")}
                onChange={(e) => setSchool(e.target.value)}
                borderColor="gray.800"
                id="school"
                disabled={!university}
              >
                {schools.map((school) => (
                  <option key={school.id} value={school.id}>
                    {school.name.en}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl
              isInvalid={emptyFields.includes("degreeType")}
              isRequired
            >
              <FormLabel htmlFor="studyProgramType">{t("degree_type")}</FormLabel>
              <RadioGroup
                value={studyProgramType}
                isDisabled={!university || !school}
                onChange={(value) => {
                  setStudyProgramType(value);
                  resetStudyProgram();
                }}
                id="studyProgramType"
              >
                <Stack direction="row">
                  <Radio value="BACHELOR" borderColor="gray.800">
                    {t("bachelor")}
                  </Radio>
                  <Radio value="MASTER" borderColor="gray.800">
                    {t("master")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl
              isInvalid={emptyFields.includes("studyProgram")}
              isRequired
            >
              <FormLabel htmlFor="studyProgram">
                {t("select_study_program")}
              </FormLabel>
              <Select
                placeholder={t("select_study_program")}
                value={studyProgram}
                onChange={(e) => setStudyProgram(e.target.value)}
                isDisabled={!university || !school}
                borderColor="gray.800"
                id="university"
              >
                {studyPrograms.map((studyProgram) => (
                  <option key={studyProgram.id} value={studyProgram.id}>
                    {studyProgramType === "BACHELOR"
                      ? t("bachelor")
                      : t("master")}{" "}
                    {studyProgram.name.en}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isInvalid={emptyFields.includes("inviteCode")} isRequired>
              <FormLabel htmlFor="inviteCode">{t("invite_code")}</FormLabel>
              <Input
                placeholder={t("your_invite_code")}
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                borderColor="gray.800"
                id="inviteCode"
              />
              {inviteCode && invitedBy && (
                <Text fontSize="sm" color="green.500">
                  {"Nice, "}
                  <strong>{invitedBy}</strong>
                  {" " + t("invited_you")}
                </Text>
              )}
              {inviteCodeError && (
                <Text color="red.500" fontSize="sm">
                  {inviteCodeError}
                </Text>
              )}
            </FormControl>
            <FormControl
              isInvalid={emptyFields.includes("agreeTerms")}
              isRequired
            >
              <Checkbox
                isChecked={agreeTerms}
                onChange={(e) => setAgreeTerms(e.target.checked)}
                borderColor="gray.800"
                id="agreeTerms"
                mt={2}
              >
                {createTextWithLinks(t("agree_terms"), {
                  [t("terms_of_service")]: "/terms-of-service",
                  [t("privacy_policy")]: "/privacy-policy",
                })}
              </Checkbox>
            </FormControl>

            {errors && (
              <Text color="red.500" fontSize="sm">
                {errors}
              </Text>
            )}

            <Stack direction="row" spacing={2}>
              <Button
                fontFamily="heading"
                mt={2}
                width="50%"
                onClick={() => navigate("/")}
              >
                {isLargerThan768
                  ? t("explore_without_signing_up")
                  : t("explore_as_guest")}
              </Button>
              <Button
                fontFamily="heading"
                onClick={handleSignUp}
                disabled={!username || !email || !password || !agreeTerms}
                bg="main.primary"
                _hover={{ bg: "main.secondary" }}
                color="white"
                mt={2}
                width="50%"
              >
                {t("sign_up_button")}
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Stack direction="row" justify="center" align="center">
          <Text fontSize="sm">{t("already_have_account")}</Text>
          <Link fontSize="sm" href="/sign-in" fontWeight="bold">
            {t("sign_in")}
          </Link>
        </Stack>
      </Stack>
      <Box h={8} />
    </Box>
  );
}
