import { Skeleton, Tag, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { nameTranslation } from './utils/Utils';
function CourseTag({ course, isLoading, truncate }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const truncateName = (name, truncate) => {

    if (!truncate)
      return name;

    const words = name.split(" ");
    const hasLongWord = words.some(word => word.length > 15);

    if (hasLongWord) {
      return name.slice(0, 13) + "...";
    }

    return name;
  }

  return isLoading ? (
    <Tag
      size="lg"
      variant="solid"
      colorScheme="blue"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      display="flex"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      borderRadius={70}
      px={4}
      py={2}
      color="black"
      _hover={{ background: "main.secondary" }}
    >
      <Skeleton height="20px" width="100px" />
    </Tag>
  ) : (
    <Tag
      size="lg"
      variant="solid"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      display="flex"
      bg={useColorModeValue(null, "main.elementBgDark")}
      borderRadius={70}
      px={4}
      py={2}
      _hover={{ background: "main.secondary" }}
      onClick={() => navigate(`/course/${course.id}`)}
    >
      {truncateName(nameTranslation(course.name, i18n.language), truncate)}
    </Tag>
  );
}

export default CourseTag;
