import { useState, useEffect } from "react";
import { Box, Text, Image } from "@chakra-ui/react";

const NotFoundPage = () => {
  const [redirectTimer, setRedirectTimer] = useState(5);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     window.location.href = "/";
  //   }, redirectTimer * 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [redirectTimer]);

  // useEffect(() => {
  //   if (redirectTimer > 0) {
  //     const interval = setInterval(() => {
  //       setRedirectTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   } else {
  //     window.location.href = "/";
  //   }
  // }, [redirectTimer]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Text fontSize="5xl" fontWeight="bold" mt={5} textAlign="center">
        Hoppala, da hast du dich wohl verlaufen.
      </Text>
      <Text fontSize="2xl" textAlign="center">
        Keine Angst, <a  style={{ textDecoration: 'underline', color: 'inherit' }}fontWeight="bold" href="#" onClick={() => window.history.back()}>hier</a> gehts zurück.
      </Text>

      <Box
        position="relative"
      >
        <Image
          src="/Maggus.png"
          alt="404"
          // boxSize="400px"
          objectFit="cover"

          mb={4}
        />
      </Box>
      <Text fontSize="xl" textAlign="center" cursor={"pointer"} onClick={() => window.location.href = "https://www.instagram.com/explore/tags/s%C3%B6derisst/top/"}>
        Hast du etwa Hunger?
      </Text>
    </Box>
  );
};

export default NotFoundPage;
