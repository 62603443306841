import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Icon,
  Text,
  Flex,
  Input,
  Switch,
  Textarea,
  useToast,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import request from "../api";
import {
  BsHandThumbsUp,
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUpFill,
} from "react-icons/bs";
import RateDetail from "./RateDetail";
export default function RatingButtonWithModal({
  courseID,
  setHasRated,
  fetchComments,
  fetchCourseData,
  updateUser,
  setUpdateUser,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const [ratingStars, setRatingStars] = useState(0);
  const [ratingHover, setRatingHover] = useState(0);
  const [ratingSelected, setRatingSelected] = useState(false);
  const [wouldRecommend, setWouldRecommend] = useState(false);
  const [wouldNotRecommend, setWouldNotRecommend] = useState(false);
  const [grade, setGrade] = useState("");
  const [bonus, setBonus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attendance, setAttendance] = useState(50);
  const [previousKnowledge, setPreviousKnowledge] = useState(50);
  const [content, setContent] = useState(50);
  const [effortPerCredit, setEffortPerCredit] = useState(50);
  const [reproductionVsTransfer, setReproductionVsTransfer] = useState(50);
  const [mathIntensity, setMathIntensity] = useState(50);
  const [comment, setComment] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const MAX_COMMENT_LENGTH = 500;
  const toast = useToast();
  const { t } = useTranslation();
  const handleRatingHoverEnter = (value) => {
    setRatingHover(value);
    setMouseIn(true);
  };

  const handleRatingHoverLeave = (value) => {
    setMouseIn(false);
    setRatingHover(value);
  };

  const handleRatingClick = (value) => {
    setRatingSelected(true);
    setRatingStars(value);
  };

  const handleRecommendClick = (value) => {
    setWouldRecommend(value);
    setWouldNotRecommend(!value);
  };

  const handleGradeChange = (event) => {
    setGrade(event.target.value);
  };

  const handleBonusChange = () => {
    setBonus(!bonus);
  };

  const handleCommentChange = (value) => {
    if (value.length <= MAX_COMMENT_LENGTH) {
      setComment(value);
      setCharacterCount(value.length);
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveStep(0);
    setErrorMessage("");
  };

  const openErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const sendRating = async () => {
    if (comment.length < 20) {
      openErrorModal();
      setErrorMessage(t("error_modal_description"));
      return;
    }
    let response;
    try {
      response = await createRating();
      fetchCourseData();
      fetchComments();
      setHasRated(true);
      setUpdateUser(!updateUser);
    } catch (error) {
      toast({
        title: t("error_modal_title"),
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
        "POST",
        { text: comment.trim(), elementID: response.id, elementType: "comment" }
      );
    } catch (error) {
      console.error("Failed to translate comment:", error);
    }

    setIsOpen(false);
    setActiveStep(0);
    setErrorMessage("");
  };

  const goToNextStep = () => {
    setGrade(parseFloat(grade));
    if (
      activeStep === 0 &&
      (ratingStars === 0 ||
        (wouldRecommend === false && wouldNotRecommend === false) ||
        grade === "")
    ) {
      openErrorModal();
      setErrorMessage(t("please_answer_all_questions"));
      return;
    }
    if (
      activeStep === 0 &&
      (grade < 1 || (grade > 4 && grade !== 5) || (grade * 10) % 1 !== 0)
    ) {
      openErrorModal();
      setErrorMessage(t("enter_valid_grade"));
      return;
    }
    if (
      activeStep === 1 &&
      attendance === 50 &&
      previousKnowledge === 50 &&
      content === 50 &&
      effortPerCredit === 50 &&
      reproductionVsTransfer === 50 &&
      mathIntensity === 50
    ) {
      openErrorModal();
      setErrorMessage(t("really_that_average"));
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
    setErrorMessage("");
  };

  const goToPreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setErrorMessage("");
  };

  const createRating = async () => {
    const rating = {
      grade: grade,
      ratingStars: ratingStars,
      wouldRecommend: wouldRecommend,
      hasBonus: bonus,
      attendance: attendance,
      previousKnowledge: previousKnowledge,
      content: content,
      effortPerCredit: effortPerCredit,
      reproductionVsTransfer: reproductionVsTransfer,
      mathIntensity: mathIntensity,
      comment: comment.trim(),
    };

    const response = await request(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/rate`,
      "POST",
      rating
    );

    return response;
  };

  const steps = [
    { title: t("general") },
    { title: t("details") },
    { title: t("comment") },
  ];

  return (
    <>
      <Button color="white" onClick={openModal} bg="green.500" size="sm" mb={1}>
        {t("rate_course")}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size={isLargerThan768 ? "xl" : "sm"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h1>{t("rate_course")}</h1>
            <Stepper index={activeStep} my={4}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box>
                    {isLargerThan768 && <StepTitle>{step.title}</StepTitle>}
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {activeStep === 0 && (
              <div>
                <Box>
                  <Flex mb={4} borderRadius={4} shadow="md" p={2}>
                    <Text fontSize={"xl"}>{t("overall_experience")}</Text>
                    <Box flex="1" />
                    {isLargerThan768 ? [1, 2, 3, 4, 5].map((value) => (
                      <Icon
                        key={value}
                        as={
                          (ratingSelected && !mouseIn
                            ? ratingStars
                            : ratingHover) >= value
                            ? AiFillStar
                            : AiOutlineStar
                        }
                        boxSize={8}
                        color={
                          (ratingSelected && !mouseIn
                            ? ratingStars
                            : ratingHover) >= value
                            ? "yellow.400"
                            : "gray.300"
                        }
                        onMouseEnter={() => handleRatingHoverEnter(value)}
                        onMouseLeave={() =>
                          ratingSelected
                            ? handleRatingHoverLeave(ratingStars)
                            : handleRatingHoverLeave(0)
                        }
                        onClick={() => handleRatingClick(value)}
                        cursor="pointer"
                      />
                    )) : [1, 2, 3, 4, 5].map((value) => (
                      <Icon
                        key={value}
                        as={ratingStars >= value ? AiFillStar : AiOutlineStar}
                        boxSize={8}
                        color={ratingStars >= value ? "yellow.400" : "gray.300"}
                        onClick={() => handleRatingClick(value)}
                        cursor="pointer"
                      />
                    ))}
                  </Flex>

                  <Flex mb={4} borderRadius={4} shadow="md" p={2}>
                    <Text fontSize={"xl"}>{t("recommend_course")}</Text>
                    <Box flex="1" />
                    <Icon
                      as={
                        wouldNotRecommend
                          ? BsHandThumbsDownFill
                          : BsHandThumbsDown
                      }
                      boxSize={8}
                      color={"red.500"}
                      onClick={() => handleRecommendClick(false)}
                      cursor="pointer"
                    />
                    <Icon
                      boxSize={8}
                      as={wouldRecommend ? BsHandThumbsUpFill : BsHandThumbsUp}
                      mr={2}
                      color={"green.500"}
                      onClick={() => handleRecommendClick(true)}
                      cursor="pointer"
                    />
                  </Flex>

                  <Flex mb={4} borderRadius={4} shadow="md" p={2}>
                    <Text fontSize={"xl"}>{t("final_grade")}</Text>
                    <Box flex="1" />
                    <Input
                      variant="outline"
                      height={"auto"}
                      width={isLargerThan768 ? "12%" : "20%"}
                      type="number"
                      value={grade}
                      onChange={handleGradeChange}
                      textAlign={"center"}
                    />
                  </Flex>

                  <Flex mb={4} borderRadius={4} shadow="md" p={2}>
                    <Text fontSize={"xl"}>{t("has_bonus")}</Text>
                    <Box flex="1" />
                    <Switch
                      colorScheme="green"
                      isChecked={bonus}
                      onChange={handleBonusChange}
                    />
                  </Flex>
                  {/* <Text color="red.500" textAlign={'center'}>
                    {errorMessage}
                  </Text> */}
                </Box>
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <RateDetail
                  attendance={attendance}
                  setAttendance={setAttendance}
                  previousKnowledge={previousKnowledge}
                  setPreviousKnowledge={setPreviousKnowledge}
                  content={content}
                  setContent={setContent}
                  effortPerCredit={effortPerCredit}
                  setEffortPerCredit={setEffortPerCredit}
                  reproductionVsTransfer={reproductionVsTransfer}
                  setReproductionVsTransfer={setReproductionVsTransfer}
                  mathIntensity={mathIntensity}
                  setMathIntensity={setMathIntensity}
                />
                {/* <Text color="red.500" textAlign={'center'}>
                  {errorMessage}
                </Text> */}
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <Box>
                  <Text
                    fontSize={"md"}
                    textAlign={"center"}
                    mb={2}
                    fontWeight={"bold"}
                  >
                    {t("last_but_not_least")}
                  </Text>
                  <Text fontSize={"sm"} textAlign={"center"} mb={4}>
                    {t("comment_hints")}
                  </Text>
                  <Textarea
                    placeholder={t("comment_placeholder")}
                    size="lg"
                    fontSize={"md"}
                    value={comment}
                    onChange={(e) => handleCommentChange(e.target.value)}
                    style={{ height: "180px" }}
                    maxLength={MAX_COMMENT_LENGTH}
                  />
                  <Text
                    fontSize={"sm"}
                    textAlign={"right"}
                    color={characterCount == MAX_COMMENT_LENGTH ? "red" : null}
                  >
                    {t("characters_left", {
                      characterCount: characterCount,
                      maxCommentLength: MAX_COMMENT_LENGTH,
                    })}
                    {/* {characterCount}/{MAX_COMMENT_LENGTH} characters left */}
                  </Text>
                  {/* <Text color="red.500" textAlign={'center'}>
                    {errorMessage}
                  </Text> */}
                </Box>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {activeStep !== 0 && (
              <Button mr={3} onClick={goToPreviousStep}>
                {t("back")}
              </Button>
            )}
            {activeStep !== 2 ? (
              <Button
                color="white"
                bg="main.primary"
                _hover={{ bg: "main.secondary" }}
                onClick={goToNextStep}
              >
                {t("next")}
              </Button>
            ) : (
              <Button
                color="white"
                bg="main.primary"
                _hover={{ bg: "main.secondary" }}
                onClick={sendRating}
              >
                {t("send")}
              </Button>
            )}
          </ModalFooter>
          {isErrorModalOpen && (
            <Modal
              isOpen={isErrorModalOpen}
              onClose={closeErrorModal}
              size="sm"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{t("rating_error_modal_title")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>{errorMessage}</Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="white"
                    bg="main.primary"
                    _hover={{ bg: "main.secondary" }}
                    onClick={closeErrorModal}
                  >
                    {t("close")}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
