import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import request from "../api";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import AuthContext from "../AuthContext";

export default function DeleteAccount({ user }) {
  const { handleSignOut } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const verifyPassword = async (password) => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/verify-password`,
        "POST",
        { password: password }
      );
      setPasswordCorrect(true);
    } catch (error) {
      setPasswordCorrect(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${user.id}`,
        "DELETE"
      );
      handleSignOut();
    } catch (error) {}
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirm = (event) => {
    setConfirmed(event.target.checked);
  };

  const reasonsList = [
    "No longer using the platform",
    "Privacy concerns",
    "Found a better alternative",
    "Account-related issues",
    "I've already graduated",
    "Other",
  ];

  return (
    <Box display="flex" alignItems="center">
      <Box flex="1" ml="2">
        <Heading fontSize="xxl" mb="4">
          <Box as="span" display="flex" alignItems="center">
            <AiOutlineDelete size={24} style={{ marginRight: "0.5rem" }} />
            {t("delete_account.heading")}
          </Box>
        </Heading>
        <Text fontSize="sm" mb="2" maxW="500px">
          {t("delete_account.subheading")}
        </Text>
        <Stack spacing="2">
          <FormControl mt="4" maxWidth="300px">
            <FormLabel fontSize="sm">
              {t("delete_account.password_prompt")}
            </FormLabel>
            <InputGroup size="s">
              <Input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  verifyPassword(e.target.value);
                }}
                borderColor="gray.800"
                _placeholder={{ color: "gray.500" }}
                id="password"
                color="gray.800"
                size="sm"
              />
              <InputRightElement width="3rem" height="2rem">
                {showPassword ? (
                  <ViewOffIcon
                    cursor="pointer"
                    onClick={handleTogglePassword}
                  />
                ) : (
                  <ViewIcon cursor="pointer" onClick={handleTogglePassword} />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Checkbox
            mt="5"
            size="sm"
            key="confirm"
            value="confirm"
            isChecked={confirmed}
            onChange={handleConfirm}
          >
            <Text fontWeight="bold" fontSize="sm">
              {t("delete_account.confirm_checkbox")}
            </Text>
          </Checkbox>
          <Button
            mt="4"
            colorScheme="red"
            onClick={handleDeleteUser}
            width="200px"
            size="sm"
            isDisabled={!passwordCorrect || !confirmed}
            borderRadius="md"
            _hover={{ bg: "red.600" }}
            _focus={{ outline: "none" }}
            _active={{ bg: "red.700" }}
          >
            {t("delete_account.delete_button")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
