import React from "react";
import {
  Box, Skeleton,
  LinkBox, Flex, HStack, useColorModeValue
} from "@chakra-ui/react";

export const SkeletonSearchCard = () => (
  <LinkBox
    as="div"
    p={4}
    borderWidth={1}
    borderRadius="md"
    shadow={"base"}
    border={"none"}
    mb={4}
    bg={useColorModeValue( "main.elementBgLight", "main.elementBgDark")}
    cursor="pointer"
    transition="background-color 0.3s"
  >
    <Flex justify="space-between" alignItems="flex-start">
      <Box flex="1">
        <HStack spacing={2} mb={2}>
          <Skeleton height="20px" width="70%" />
          <Skeleton height="16px" width="30%" />
        </HStack>
        <HStack spacing={2} mb={2}>
          {/* <Icon as={FaUniversity} /> */}
          <Skeleton height="16px" width="50%" />
        </HStack>
        <Box mb={2}>
          <HStack spacing={2} mb={2}>
            {/* <Icon as={FaUser} /> */}
            <Skeleton height="16px" width="50%" />
          </HStack>
          <HStack spacing={2} mb={2}>
            {/* <Icon as={FaUser} /> */}
            <Skeleton height="16px" width="50%" />
          </HStack>
        </Box>
        <HStack spacing={2} mb={2}>
          <Skeleton height="20px" width="20%" />
          <Skeleton height="20px" width="20%" />
        </HStack>
        <HStack spacing={6}>
          <Skeleton height="20px" width="20%" />
          <Skeleton height="20px" width="20%" />
          <Skeleton height="20px" width="20%" />
          <Skeleton height="20px" width="20%" />
        </HStack>
      </Box>
      <HStack>
        <Skeleton height="20px" width="80px" />
      </HStack>
    </Flex>
  </LinkBox>
);
