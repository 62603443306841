import React from 'react';
import { useState, useEffect } from 'react';
import { MultiSelect, useMultiSelect } from 'chakra-multiselect';

export default function AreaSingleSelect({
  areas,
  selectedArea,
  setSelectedArea,
}) {
  if (areas == null) {
    return null;
  }
  if (selectedArea == null) {
    selectedArea = '';
  }
  const { value, options, onChange } = useMultiSelect({
    value: selectedArea,
    options: areas,
  });
  useEffect(() => {
    if (value !== '' || value !== null) setSelectedArea(value);
  }, [value]);
  return (
    <MultiSelect
      options={options}
      value={value}
      placeholder="Select or create Areas"
      onChange={onChange}
      create
      single
    />
  );
}
