import React, { useEffect, useState } from "react";
import { HStack, Alert, AlertIcon, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import request from "../api";


export default function VerifyEMailComponent() {
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const toast = useToast()


  useEffect(() => {
    checkVerificationStatus();
  }, []);


  async function checkVerificationStatus() {

    try {
      const data = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/verification-status`, "GET", null);
      setEmailVerified(data.emailVerified);
    } catch (error) {
      console.error("Error checking verification status:", error);
      setEmailVerified(false);
    } finally {
      setIsLoading(false);
    }
  };

  async function handleResendVerificationEmail() {

    try {
      if (buttonDisabled) {
        return;
      }

      const data = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/request-verify-email`, "GET");

      setVerificationStatus("Email verification successful!");
      toast({
        title: 'Verification Mail',
        description: 'You will get a verification link via mail.',
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while verifying email!")
      setVerificationStatus("An error occurred while verifying email!");
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
  };

  return (

    <>
      {
        !emailVerified && (
          <HStack align="center" >
            <Alert
              status="info"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              bg="rgba(0, 0, 255, 0.1)"
              
              fontSize="sm"
              fontWeight="bold"
              // borderWidth={1}
          
              p={2}
            
              
            >
              <AlertIcon />
              You are not verified yet. To do so, click{' '}
              <Text
                fontWeight="bold"
                textDecoration="underline"
                onClick={handleResendVerificationEmail}
                role="button"
                display="inline"
                mr={1}
                ml={1}
                cursor="pointer"
              >
                {'here'}
              </Text>{' '}
              to verify your email.
            </Alert>
          </HStack>
        )}
    </>

  );
}
