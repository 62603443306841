import React from "react";
import { Box, Flex, Text, Progress, Tooltip, useColorModeValue, useMediaQuery } from "@chakra-ui/react";

export default function MetricBar({ name, value, upperScale, lowerScale, isLargerThan768 }) {
  return (
    <Box mb={2} bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")} >
      <Flex alignItems="center" mb={2}>
        <Text fontWeight="bold" mb={isLargerThan768 ? 2 : 0} fontSize={isLargerThan768 ? null : "sm"}>
          {name}
        </Text>
      </Flex>
      <Tooltip label={value + "%"} openDelay={500}>
        {/* TODO: Progress bar color theme */}
        <Progress
          value={value}
          // colorScheme="main"
          mb={2}
          borderRadius="md"
          zIndex={0}
        />
      </Tooltip>
      <Box display="flex" justifyContent="space-between">
        <Text fontSize={isLargerThan768 ? null : "xs"}>
          {lowerScale}</Text>
        <Text fontSize={isLargerThan768 ? null : "xs"}>{upperScale}</Text>
      </Box>
    </Box>
  );
}
