

export const nameTranslation = (elementName, lng) => {
  if (elementName === null) return "";
  if (lng === "de") {
    if (elementName.de !== null) return elementName.de;

    return elementName.en;
  } else {
    if (elementName.en !== null) return elementName.en;
    else return elementName.de;
  }
};


