import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SimpleGrid,
  Text,
  Input,
  Checkbox,
  Switch,
  HStack,
  Select,
  Box,
  Button,
  useToast,
  Spacer,
} from '@chakra-ui/react';

import AreaSingleSelect from './utils/AreaSingleSelect';
import ResponsibleMultiSelect from './utils/ResponsibleMultiSelect';
import request from '../api';
import { Navigate } from 'react-router-dom';
export default function EditCourseDetails({
  courseData,
courseID,
  setIsEditing,
  fetchCourseData,
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const [areas, setAreas] = useState(null);
  const [selectedResponsibles, setSelectedResponsibles] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [responsibles, setResponsibles] = useState(null);
  const [courseCode, setCourseCode] = useState(courseData?.courseCode || '');
  const [isBonus, setIsBonus] = useState(courseData?.hasBonus || false);
  const [semesterOptions, setSemesterOptions] = useState(
    courseData?.offeredInSemesters || []
  );
  const [credits, setCredits] = useState(courseData?.credits || 0);
  const [levelOptions, setLevelOptions] = useState(
    courseData?.moduleLevel || []
  );
  const [languageOptions, setLanguageOptions] = useState(
    courseData?.languages || []
  );
  const [courseWebsite, setCourseWebsite] = useState(
    courseData?.website || ' '
  );
 const [error, setError] = useState('');
  const fetchAreas = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/areas`,
        'GET'
      );
      setAreas(response.areas);
    } catch (error) {
    }
  };

  const fetchResponsibles = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/responsibles`,
        'GET'
      );

      setResponsibles(response.responsibles);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchAreas();
    fetchResponsibles();
    if (courseData) {
      setSelectedResponsibles(
        courseData?.responsibles.map(resp => resp.displayName)
      );
      setSelectedArea(courseData?.area);
      setCourseCode(courseData?.courseCode);
      setIsBonus(courseData?.hasBonus);
      setSemesterOptions(courseData?.offeredInSemesters);
      setCredits(courseData?.credits);
      setLevelOptions(courseData?.moduleLevel);
      setLanguageOptions(courseData?.languages);
      setCourseWebsite(courseData?.website);
    }
  }, [courseData]);

  if (!courseData) {
    return null;
  }

  const handleChanges = async () => {
    const body = {};
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!urlPattern.test(courseWebsite)) {
      setError('Please enter a valid URL');
      return;
    }
    if (
      courseData?.responsibles
        .map(resp => resp.displayName)
        .sort()
        .join(',') !=
      selectedResponsibles
        .map(resp => resp.trim())
        .sort()
        .join(',')
    )
      body.responsibles = selectedResponsibles;
    if (courseData?.area != selectedArea) body.area = selectedArea.trim();
    if (courseData?.courseCode != courseCode) body.courseCode = courseCode;
    if (courseData?.hasBonus != isBonus) body.hasBonus = isBonus;
    if (courseData?.offeredInSemesters != semesterOptions)
      body.offeredInSemesters = semesterOptions;
    if (courseData?.credits != credits) body.credits = credits;
    if (courseData?.moduleLevel != levelOptions)
      body.moduleLevel = levelOptions;
    if (courseData?.languages != languageOptions)
      body.languages = languageOptions;
    if (courseData?.website != courseWebsite) body.website = courseWebsite;

    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/updateCourseDetails`,
        'PUT',
        body
      );
      if (Object.keys(body).length !== 0) {
        toast({
          title: 'Course updated successfully',
          description: 'Course details updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error updating course',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsEditing(false);
    fetchCourseData();
  };

  return (
    <Box
      borderRadius={4}
      shadow="md"
      p={2}
      borderColor={'black'}
      alignSelf={'center'}
   
      justifyContent="center"
      alignItems="center"
      bg={'white'}
    >
      <SimpleGrid templateColumns="auto auto" spacing={2} ml={2} mb={2}>
        <Text fontSize="large" fontWeight="bold" mr={2}>
          Course Code:
        </Text>
        <Input
          value={courseCode}
          onChange={e => setCourseCode(e.target.value.trim())}
        />

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Area:
        </Text>
        <AreaSingleSelect
          areas={areas}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
        />

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Bonus
        </Text>
        <Switch onChange={e => setIsBonus(e.target.checked)} />

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Semester:
        </Text>
        <HStack spacing={2}>
          <Checkbox
            isChecked={semesterOptions?.includes('WS')}
            onChange={e =>
              setSemesterOptions(
                e.target.checked
                  ? semesterOptions.concat('WS')
                  : semesterOptions.filter(item => item !== 'WS')
              )
            }
          >
            Winter
          </Checkbox>
          <Checkbox
            isChecked={semesterOptions?.includes('SS')}
            onChange={e =>
              setSemesterOptions(
                e.target.checked
                  ? semesterOptions.concat('SS')
                  : semesterOptions.filter(item => item !== 'SS')
              )
            }
          >
            Summer
          </Checkbox>
          <Checkbox
            isChecked={semesterOptions?.includes('IRREGULARLY')}
            onChange={e =>
              setSemesterOptions(
                e.target.checked
                  ? semesterOptions.concat('IRREGULARLY')
                  : semesterOptions.filter(item => item !== 'IRREGULARLY')
              )
            }
          >
            Irregular
          </Checkbox>
        </HStack>

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Credits:
        </Text>
        <HStack spacing={2}>
          <Input
            value={credits}
            onChange={e => setCredits(e.target.value.trim())}
          />
          <Box flex={1} />
        </HStack>

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Level:
        </Text>
        <HStack spacing={2}>
          <Checkbox
            isChecked={levelOptions?.includes('BACHELOR')}
            onChange={e =>
              setLevelOptions(
                e.target.checked
                  ? levelOptions.concat('BACHELOR')
                  : levelOptions.filter(item => item !== 'BACHELOR')
              )
            }
          >
            Bachelor
          </Checkbox>
          <Checkbox
            isChecked={levelOptions?.includes('MASTER')}
            onChange={e =>
              setLevelOptions(
                e.target.checked
                  ? levelOptions.concat('MASTER')
                  : levelOptions.filter(item => item !== 'MASTER')
              )
            }
          >
            Master
          </Checkbox>
        </HStack>

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Language:
        </Text>
        <HStack spacing={2}>
          <Checkbox
            isChecked={languageOptions?.includes('GERMAN')}
            onChange={e =>
              setLanguageOptions(
                e.target.checked
                  ? languageOptions.concat('GERMAN')
                  : languageOptions.filter(item => item !== 'GERMAN')
              )
            }
          >
            German
          </Checkbox>
          <Checkbox
            isChecked={languageOptions?.includes('ENGLISH')}
            onChange={e =>
              setLanguageOptions(
                e.target.checked
                  ? languageOptions.concat('ENGLISH')
                  : languageOptions.filter(item => item !== 'ENGLISH')
              )
            }
          >
            English
          </Checkbox>
        </HStack>

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Responsibles:
        </Text>
        <ResponsibleMultiSelect
          responsibles={responsibles}
          selectedResponsibles={selectedResponsibles}
          setSelectedResponsibles={setSelectedResponsibles}
        />

        <Text fontSize="large" fontWeight="bold" mr={2}>
          Course Website:
        </Text>
        <Input
          value={courseWebsite}
          onChange={e => setCourseWebsite(e.target.value.trim())}
        />
       
      </SimpleGrid>
      <HStack justifyContent="space-between" alignItems="flex-end">
          <Spacer />
          <Text color={"red.500"} mr={5} py={2}justifySelf="center">
            {error}
          </Text>
          <Button
            mr={2}
            colorScheme="gray"
            onClick={() => setIsEditing(false)}
            variant={'outline'}
          >
            Cancel
          </Button>
          <Button
            mr={2}
            color="main.primary" borderColor="main.primary"
            onClick={handleChanges}
            variant={'outline'}
          >
            Save Changes
          </Button>
        </HStack>
    </Box>
  );
}
