import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import CourseDetailTag from './CourseDetailTag.js';
import { useTranslation } from 'react-i18next';

export default function CourseDetailRow({
  detail,
  value,
  dontChangeCase,

}) {
  if (value == null || (Array.isArray(value) && value.length == 0)) return null;
  const { i18n } = useTranslation();
  return (
    <>
      <Text fontSize="large" fontWeight="bold" mr={2} textAlign={'end'}>
        {detail}
      </Text>
      <Flex spacing={1} justifyContent="flex">
        {Array.isArray(value) ? (
          value.map(tag => (
            <CourseDetailTag
              text={
                dontChangeCase
                  ? tag
                  : tag
                      .toString()
                      .replace('SS', i18n.language == "de"? "sommer":'summer')
                      .replace('WS', 'winter')
                      .replace(/_/g, ' ')
                      .toLocaleLowerCase()
              }
             
            ></CourseDetailTag>
          ))
        ) : (
          <CourseDetailTag text={value} ></CourseDetailTag>
        )}
      </Flex>
    </>
  );
}
