import { useContext, useState } from 'react';
import React from 'react';
import Navbar from '../components/Navbar.js';
import CourseOverview from '../components/CourseOverview.js';
import BottomBar from '../components/BottomBar.js';
import AuthContext from '../AuthContext.js';
import { Spacer, Flex, Box } from '@chakra-ui/react';
import Leaderboard from '../components/Leaderboard.js';

function LeaderboardPage() {
  const { isSignedIn, role } = useContext(AuthContext);
  const allowedRoles = ['ADMIN', 'STUDENT', 'MODERATOR'];
  const [updateUser, setUpdateUser] = useState(false);

  return (
    <Flex direction="column" minH="100vh">
      <Navbar
        loggedIn={isSignedIn && allowedRoles.includes(role)}
        updateUser={updateUser}
      />

      
      <Leaderboard />

      <Spacer />
      <Box mt={8} >
      <BottomBar />
      </Box>
    </Flex>
  );
}

export default LeaderboardPage;
