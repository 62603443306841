import React from "react";
import { Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import request from "../api";
export default function LikeDislikeButton({
  isLikedByUser,
  isDislikedByUser,
  likes,
  dislikes,
  courseID,
  route,
  fetchData,
  updateUser,
  setUpdateUser
}) {
  // const [isLiked, setIsLiked] = useState(isLikedByUser);
  // const [isDisliked, setIsDisliked] = useState(isDislikedByUser);
  // const [likeCount, setLikeCount] = useState(likes);
  // const [dislikeCount, setDislikeCount] = useState(dislikes);

  const toast = useToast();
  const handleLike = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${route}/like`,
        'POST'
      );
      if (isLikedByUser) {
        likes -= 1;
        isLikedByUser = false;
      } else {
        likes += 1;
        isLikedByUser = true;
        if (isDislikedByUser) {
          dislikes -= 1;
          isDislikedByUser = false;
        }
      }
      setUpdateUser(!updateUser);
      fetchData();
    } catch (error) {
      toast({
        title: 'Damn, something went wrong...',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDislike = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${route}/dislike`,
        'POST'
      );

      if (isDislikedByUser) {
        dislikes -= 1;
        isDislikedByUser = false;
      } else {
        dislikes += 1;
        isDislikedByUser = true;
        if (isLikedByUser) {
          likes -= 1;
          isLikedByUser = false;
        }
      }
      setUpdateUser(!updateUser);
      fetchData();
    } catch (error) {
      toast({
        title: 'Damn, something went wrong...',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex direction="column" alignItems="center" ml={4} mb={1}>
      <IconButton
        aria-label="Like"
        variant="ghost"
        icon={
          <ChevronUpIcon color={isLikedByUser ? "main.primary" : "gray.500"} boxSize={8} />
        }
        onClick={handleLike}
        size="sm"
      />
      <Tooltip label={likes + " up,  " + dislikes + " down"}>
        <Text fontSize="sm" fontWeight="bold">
          {likes - dislikes}
        </Text>
      </Tooltip>
      <IconButton
        aria-label="Dislike"
        variant="ghost"
        icon={
          <ChevronDownIcon
            color={isDislikedByUser ? "main.primary" : "gray.500"}
            boxSize={8}
          />
        }
        onClick={handleDislike}
        size="sm"
      />
    </Flex>
  );
}
