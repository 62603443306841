import { extendTheme } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";
// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  main: {
    primary: "#0668E1",
    secondary: "#90CDF4",  // secondary: "#0080FB",
    500: "#0668E1",
    elementBgDark: "#2D3748",
    elementBgLight: "#F7FAFC",

    // primary: "#47b61c",
    // secondary: "#5be327",
    // 500: "#47b61c",
    gray: "#999999",
    white: "#f5f5f5",
    black: "#343434",
  },
  // university: {
  //   primary: "#ff0000",
  //   secondary: "#0000ff",
  //   gray: "#999999",
  //   white: "#f5f5f5",
  //   black: "#343434",
  // },
};
const config = {
  initialColorMode: "system",
  useSystemColorMode: true,
};
const styles = {
  global: (props) => ({
    body: {
      bg: props.colorMode === "dark" ? "#1A202C" : "gray.100",
      color: props.colorMode === "dark" ? "white" : "black",
    },
  }),
};
const components = {
  MultiSelect: MultiSelectTheme,
};

export const CustomTheme = extendTheme({ colors, styles, components, config });
