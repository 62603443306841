import React from "react";
import { useMediaQuery } from "@chakra-ui/react";
import AuthLayout from "../components/layouts/AuthLayout";
import MobileAuthLayout from "../components/layouts/MobileAuthLayout";
import SignUpComponent from "../components/SignUpComponent";

function SignUp() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  return (isLargerThan768 ? (
    <AuthLayout>
      <SignUpComponent />
    </AuthLayout>
  ): (
    <MobileAuthLayout>
      <SignUpComponent />
    </MobileAuthLayout>
    ));
}

export default SignUp;
