import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup, Flex,
    Heading, Icon, Input,
    Select, Stack, Stat, StatLabel, StatNumber,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td, Text,
    Tfoot,
    Th,
    Thead,
    Tr, Wrap, WrapItem
} from "@chakra-ui/react";
import request from "../api";
import AuthContext from "../AuthContext";
import UnauthorizedPage from "../components/UnauthorizedPage";
import Comment from "../components/Comment";
import UserMessageList from "../components/UserMessageList";
import Report from "../components/Report";
import StatCard from "../components/StatCard";
import Navbar from "../components/Navbar";
import ReportList from "../components/ReportList";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import UserTable from "../components/UserTable";

function Admin() {

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState("");
    const [messages, setMessages] = useState([]);
    const [reportedComments, setReportedComments] = useState([]);
    const [reportedAnswers, setReportedAnswers] = useState([]);
    const [reportedQuestions, setReportedQuestions] = useState([]);

    const [stats, setStats] = useState('')
    const {isSignedIn, role} = useContext(AuthContext);
    const allowedRoles = ['ADMIN', 'MODERATOR'];

    const reports = [...new Set([...reportedComments, ...reportedQuestions, ...reportedAnswers])];

    useEffect(() => {
        fetchProfile();
        fetchUsers();
        fetchReportedComments();
        fetchReportedQuestions()
        fetchReportedAnswers()
        fetchUserMessages();
        fetchAppStatistics();
    }, []);


    const fetchAppStatistics = async () => {
        try {
            const stats = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/stats`,
                "GET"
            );
            setStats(stats);
        } catch (error) {
            console.error('Failed to fetch app statistics:', error);
        }
    }

    const fetchReportedComments = async () => {
        try {
            let comments = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/comments`,
                "GET"
            );
            comments = comments.map((comment) => ({
                ...comment,
                postedBy: {
                    ...comment.postedBy,
                    avatarID: comment.postedBy.avatar
                },
                type: "COMMENT",
                courseID: comment.connectedRating.connectedCourse.courseID

            }));
            setReportedComments(comments);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }

    const fetchReportedQuestions = async () => {
        try {
            let questions = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/questions`,
                "GET"
            );
            questions = questions.map((question) => ({
                ...question,
                postedBy: {
                    ...question.postedBy,
                    avatarID: question.postedBy.avatar
                },
                type: "QUESTION",
                courseID: question.connectedCourse.courseID
            }))
            setReportedQuestions(questions)
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }

    const fetchReportedAnswers = async () => {
        try {
            let answers = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/answers`,
                "GET"
            );
            answers = answers.map((answer) => ({
                ...answer,
                postedBy: {
                    ...answer.postedBy,
                    avatarID: answer.postedBy.avatar
                },
                type: "ANSWER",
                courseID: answer.connectedQuestion?.connectedCourse.courseID
            }))
            setReportedAnswers(answers)
        } catch (error) {
            console.error('Failed to fetch user answers:', error);
        }
    }

    const fetchProfile = async () => {
        try {
            const user = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user-profile`,
                "GET"
            );
            setUser(user);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }

    const fetchUsers = async () => {
        try {
            const users = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users`, "GET");
            setUsers(users);
            setTotalUsersCount(users.length);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };

    const handleBan = async (userId) => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${userId}/ban`, "PUT");
            fetchUsers();
            fetchReportedComments();
            fetchReportedQuestions();
            fetchReportedAnswers();
        } catch (error) {
            console.error("Failed to ban user:", error);
        }
    }

    const handleUnban = async (userId) => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${userId}/unban`, "PUT");
            fetchUsers();
            fetchReportedComments();
            fetchReportedQuestions();
            fetchReportedAnswers();
        } catch (error) {
            console.error("Failed to unban user:", error);
        }
    }

    const handleChangeRole = async (role, userId) => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${userId}/role`,
                "PUT", {role: role}
            );
            await fetchUsers();
            await fetchProfile();
        } catch (error) {
            console.error("Failed to change user's role:", error);
        }
    }
    const handleDelete = async (id, type, courseID, reports) => {
        try {
            let url;
            switch (type) {
                case 'QUESTION':
                    url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/questions/${id}`
                    break;
                case 'ANSWER':
                    url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/questions/questionId/answers/${id}`
                    break;
                case 'COMMENT':
                    url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/comments/${id}`
                    break;
                default:
                    break;
            }
            const response = await request(
                url,
                'DELETE'
            );

            // Send a message to the user who reported the post
            fetchReportedComments();
            fetchReportedQuestions();
            fetchReportedAnswers();
        } catch (error) {
            console.error("Failed to delete:", error);
        }
    }

    const handleCancelReport = async (id, type) => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/cancelReport`,
                'PUT', {id: id, type: type}
            );
            // Send a message to the user who reported the post
            fetchReportedComments();
            fetchReportedQuestions();
            fetchReportedAnswers();
        } catch (error) {
        }
    }

    const fetchUserMessages = async () => {
        try {
            const messages = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/support/messages`, "GET");
            setMessages(messages);

        } catch (error) {
            console.error("Failed to fetch messages:", error);
        }
    }

    return (
        <> {isSignedIn && allowedRoles.includes(role) ?
            <>
                <Navbar
                    loggedIn={isSignedIn}

                />
                <Box mt={20} p={3}>
                    <Tabs variant='enclosed'>
                        <TabList>
                            <Tab mr={1}>USERS</Tab>
                            <Tab  mr={1}>REPORTS</Tab>
                            <Tab  mr={1}>CONTACT REQUESTS</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel borderRadius={"lg"}>
                                <UserTable admin={user} users={users} handleChangeRole={handleChangeRole}
                                           handleBan={handleBan} handleUnban={handleUnban} stats={stats}></UserTable>
                            </TabPanel>
                            <TabPanel borderRadius={"lg"}>
                                <ReportList reports={reports} handleBan={handleBan}
                                            handleCancelReport={handleCancelReport}
                                            handleUnban={handleUnban}
                                            handleDelete={handleDelete}></ReportList>
                            </TabPanel>
                            <TabPanel  borderRadius={"lg"}>
                                <UserMessageList messages={messages}
                                                 fetchUserMessages={fetchUserMessages}></UserMessageList>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box></>
            : <UnauthorizedPage/>}
        </>
    );
}

export default Admin;
