import { useContext, useState } from 'react';
import React from 'react';
import Navbar from '../components/Navbar';
import CourseOverview from '../components/CourseOverview';
import BottomBar from '../components/BottomBar';
import AuthContext from '../AuthContext.js';
import { Spacer, Flex, Box } from '@chakra-ui/react';

function Course() {
  const { isSignedIn, role } = useContext(AuthContext);
  const allowedRoles = ['ADMIN', 'STUDENT', 'MODERATOR'];
  const [updateUser, setUpdateUser] = useState(false);

  return (
    <Flex direction="column" minH="100vh">
      <Navbar
        loggedIn={isSignedIn && allowedRoles.includes(role)}
        updateUser={updateUser}
      />

      <CourseOverview updateUser={updateUser} setUpdateUser={setUpdateUser} />
      <Spacer />
      <Box mt={8} >
      <BottomBar />
      </Box>
    </Flex>
  );
}

export default Course;
